<template>
	<div id="marketingResearchDetails">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<div class="marketingResearchDetails" v-if="info">
			<div class="marketingResearchRou">
				<router-link to="/">{{$t('language.home')}}</router-link> /
				<router-link :to="$t('nav[2].src')">{{ $t('nav[2].title') }}</router-link>
			</div>
			<div class="marketingResearchDetailsTitle" v-html="info.title"></div>
			<div class="marketingResearchDetailsTime disbet">
				<span>{{info.author?info.author+'-':''}}{{ info.createTime }}</span>
				<!-- {{$t('element.author')}}：老师： -->
				<!-- <span v-if="info.author">{{info.author}}</span> -->
			</div>
			<!-- <img :src="info.cover"/> -->
			<div class="marketingResearchDetailsDesc" v-html="info.content"></div>
			<div class="imgBox">
				<img v-for="(sitem,sindex) in info.iconList" :key="sindex" :src="sitem"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'marketingResearchDetails',
		data() {
			return {
				index:0,
				info:{},
				id:0
			}
		},
		mounted() {

		},
		created() {
			this.id = this.$route.query.id || 0;
			// console.log(this.markets[this.navIndex].six);
			this.getNewsInfo();
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=marketing_research.json ':'getJsonConfig?fileName=marketing_research_en.json ';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block3.length>0&&configData.block3[0].hasOwnProperty('sortNo')){
						configData.block3 =  (configData.block3).sort((a, b) => a.sortNo - b.sortNo);
						this.info = configData.block3[this.index];
						this.info.content = this.info.content.replace(/\<img/gi,'<img style="max-width:70%;height:auto;display:block;margin:0 auto;"');
					}
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			getNewsInfo(){
				this.$http.get(this.$apiBaseUrl+'newsInfo?id='+this.id).then((res)=>{
					// console.log('res',res)
					if(res.data.content){
						res.data.content = res.data.content.replace(/\<img/gi,'<img style="max-width:70%;height:auto;display:block;margin:0 auto 30px;"');
					}
					if(res.data.icons){
						res.data['iconList'] = (res.data.icons).split(',');
					}else{
						res.data['iconList'] = [];
					}
					this.info = res.data;
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.marketingResearchDetails{
		width: 12rem;
		height: auto;
		margin: 0 auto 2.6rem;
		>img{
			width: 70%;
			display: block;
			margin: 0.3rem auto;
		}
		.marketingResearchRou{
			width: 100%;
			height:1.6rem;
			padding-top: 0.2rem;
			font-size: 0.2rem;
			color: #7F8284;
			font-weight: 700;
			line-height: 0.5rem;
			>a{
				color: #D51820;
			}
		}
		.marketingResearchDetailsTitle{
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			line-height: 0.45rem;
		}
		.marketingResearchDetailsTime{
			font-size: 0.16rem;
			color: #7F8284;
			line-height: 0.3rem;
			margin-top: 0.1rem;
			>span:last-child{
				color: #202020;
			}
		}
		.marketingResearchDetailsDesc{
			font-size: 0.16rem;
			color: #202020;
			line-height: 0.3rem;
			margin-top: 0.2rem;
			margin-bottom: 0.5rem;
		}
		.imgBox{
			width: 100%;
			height: auto;
			display: flex;
			align-items: center;
			margin-top:0.1rem;
			>img{
				display: block;
				width: auto;
				height: 0.3rem;
				margin-right: 0.1rem;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.marketingResearchDetails{
			width: 92%;
		}
	}
</style>