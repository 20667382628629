<template>
	<div id="college">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<div class="collegeBox" v-if="mainJson.block1">
			<div class="indexTitle" v-html="mainJson.block1.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block1.desc"></div>
			<div class="collegeList">
				<div class="categoryTab">
					<div class="sameCategory" :class="categoryIndex == index?'sameCategorySelect':''"
			        v-for="(item,index) in categoryList" :key="index" @click="categoryTab(index,item)">{{item}}</div>
				</div>
				<!-- :style="{backgroundImage:'url('+item.src+')'}" -->
				<div class="samecollege" v-for="(item,index) in videoList" :key="index">
					<!-- <img :src="item.src"/> -->
					<div class="videoBox">
						<!-- <vue-aliplayer-v2
							width="100%" height="100%"
							:source="item.videoSrc"
							:poster="item.cover"
							ref="VueAliplayerV2"
							:options="options"
						  /> -->
						  <video class="videoBox" controls :src="item.content" :poster="item.cover"></video>
						  
					</div>
					<div class="samecollegeTitle" v-html="item.title"></div>
					<div class="samecollegeDesc" v-html="item.remark"></div>
				</div>
			</div>
			<div class="pageBox discen" v-if="totalPage>0">
				<div class="pageButton discen" :class="page==1?'pageButtonPrev':''" @click="prevPage">{{$t('element.prevPage')}}</div>
				<div class="pageNum discen" v-for="(item,index) in totalPage" :key="index" 
				:class="(index+1 == page)?'pageNumCun':''" @click="currenPage(index+1)">{{index+1}}</div>
				<div class="pageButton discen" @click="nextPage" :class="page==totalPage?'pageButtonPrev':''">{{$t('element.nextPage')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'college',
		data() {
			return {
				mainJson:{},
				options:{
					autoplay:false,
					isLive:false
				},
				page:1,
				videoList:[],
				totalPage:1,
				category:'',
				categoryList:[],
				categoryIndex:0
			}
		},
		mounted() {

		},
		created() {
			this.getCategoryList();
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			categoryTab(index,item){
				this.categoryIndex = index;
				if(index != 0){
					this.category = item;
				}else{
					this.category = '';
				}
				this.videoList = [];
				this.page = 1;
				this.totalPage = 1;
				this.getNewsList();
			},
			getCategoryList(){
				var url = localStorage.getItem('lang') == 'zh'?'categoryList?lang=zh&module=education_videos':'categoryList?lang=en&module=education_videos'
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					this.categoryList = res.data;
					this.categoryList.unshift(localStorage.getItem('lang') == 'zh'?'全部':'All');
					this.category = '';
					this.getNewsList();
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=education.json':'getJsonConfig?fileName=education_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					this.mainJson = res.data;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			// 上一页
			prevPage(){
				if(this.page>1){
					this.page = this.page - 1;
					this.getNewsList();
				}
			},
			// 下一页
			nextPage(){
				if(this.page < this.totalPage){
					this.page = this.page + 1;
					this.getNewsList();
				}
			},
			// 当前页
			currenPage(page){
				this.page = page;
				this.getNewsList();
			},
			getNewsList(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=10'+'&page='+this.page+'&module=education_videos'+'&category='+this.category).then((res)=>{
					// console.log('res',res)
					this.totalPage = Math.ceil(res.total/10);
					this.videoList = res.rows;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.collegeBox{
		width: 12rem;
		height: auto;
		margin: 0 auto;
		padding: 1.9rem 0 2.6rem;
		.collegeList{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 1.1rem;
			.categoryTab{
				width: 100%;
				height: auto;
				display: flex;
				flex-wrap: wrap;
				.sameCategory{
					width: auto;
					height: 0.4rem;
					line-height: 0.4rem;
					margin: 0 0.3rem 0.3rem 0;
					border: 1px solid #7F8284;
					font-size: 0.2rem;
					border-radius: 0.1rem;
					padding: 0 0.2rem;
					cursor: pointer;
				}
				.sameCategorySelect{
					background: #D51820;
					color: #ffffff;
					border: 1px solid #D51820;
				}
			}
			.samecollege{
				width: 5.80rem;
				// height: 3.40rem;
				height: auto;
				background-size: cover;
				border-radius: 0.16rem;
				// padding: 0.8rem 0.64rem 0;
				margin-bottom: 0.4rem;
				>img{
					width: 0.42rem;
					height: 0.42rem;
				}
				.videoBox{
					width: 100%;
					height: 4rem;
					overflow: hidden;
				}
				.samecollegeTitle{
					width: 100%;
					height: 0.35rem;
					line-height: 0.35rem;
					font-size: 0.2rem;
					white-space: normal;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.samecollegeDesc{
					width: 100%;
					height: 0.5rem;
					font-size: 0.16rem;
					color: #7F8284;
					line-height: 0.25rem;
					white-space: normal;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.collegeBox{
			width: 100%;
			padding: 1rem 0.3rem;
			.collegeList{
				.samecollege{
					width: 90%;
					margin: 0 auto 0.4rem;
					.samecollegeTitle{
						margin-top: 0.2rem;
					}
				}
			}
		}
		
	}
</style>