import { render, staticRenderFns } from "./researchInstitute.vue?vue&type=template&id=08e7fd07&scoped=true"
import script from "./researchInstitute.vue?vue&type=script&lang=js"
export * from "./researchInstitute.vue?vue&type=script&lang=js"
import style0 from "./researchInstitute.vue?vue&type=style&index=0&id=08e7fd07&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e7fd07",
  null
  
)

export default component.exports