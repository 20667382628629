<template>
	<div id="ResearchInstitute">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner  :style="{backgroundImage:'url('+$t('marketOne.banner.src')+')'}"-->
		<div class="bannerOuter">
			<img class="bannerOuterBg" src="@/assets/images/img/commoditiesDetails.png" />
			<div class="bannerBox">
				<div class="bannerTitle">Just choose the account that works best for you. Trade <br/>wherever you want.</div>
			</div>
		</div>
		<div class="twoBox">
			<div class="mainTitle discen">Micro trading account</div>
			<div class="descTitle discen">Trade the world’s largest market for as little as $100.Master the basics of trading with minimal risk.</div>
			<div class="sameFiveList">
				<div class="smaeTabBox" v-for="(item,index) in list"  v-if="index == 0":key="index">
					<div>{{item.one}}</div>
					<div>{{item.two}}</div>
					<div>{{item.three}}</div>
					<div>{{item.four}}</div>
					<div>{{item.five}}</div>
					<div>{{item.six}}</div>
				</div>
				<div class="smaeTabBoxLine" v-for="(item,index) in list"  v-if="index > 0":key="index">
					<div>{{item.one}}</div>
					<div>{{item.two}}</div>
					<div>{{item.three}}</div>
					<div>{{item.four}}</div>
					<div class="green" :class="index == 3?'red':''">{{item.five}}</div>
					<div>
						<img :src="item.six"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ResearchInstitute',
		data() {
			return {
				tabList:[
					{id:1,title:'AllInstruments'},
					{id:2,title:'AllInstr'},
					{id:3,title:'AllInstr'}
				],
				current:0,
				formData:{
					fileName:"index_en.json"
				},
				list:[
					{id:1,one:'Market',two:'Selling price',three:'Purchase price',four:'Update time',five:'Variable value',six:'Wave curve'},
					{id:2,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:3,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:4,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:5,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:6,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:7,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					{id:8,one:'Spot silver (5000 units)',two:'2929.8',three:'2951.8',four:'16:48:59',five:'-41.5',six:require("@/assets/images/img/researchInstitute.png")},
					
				]
			}
		},
		mounted() {

		},
		created() {
			// this.getJsonConfig()
			// console.log(this.markets[this.navIndex].six)
		},
		methods: {
			tabFun(index){
				this.current = index;
			},
			getJsonConfig(){
				// http://47.236.4.7:8090/website/config/getJsonConfig?fileName=index.json
				// fileName=index_en.json  英文
				// index.json 中文
				// http://47.236.4.7:8090/website/config/getJsonConfig?fileName=index_en.json
				// http://47.236.4.7:8090/website/config/getJsonConfig?fileName=index.json
				// http://47.236.4.7:9999/app-api/website/config/getJsonConfig?fileName=index.json
				this.$http.get('http://47.236.4.7:9999/app-api/website/config/getJsonConfig?fileName=index.json').then((res)=>{
					console.log(res)
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.bannerOuter{
		width: 100%;
		height: 4.3rem;
		position: relative;
		.bannerOuterBg{
			width: 100%;
			height: 4.3rem;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		.bannerBox{
			width: 100%;
			height: 4.3rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			.bannerTitle{
				font-size: 0.44rem;
				color: #FFFFFF;
				text-align: center;
			}
		}
	}
	.twoBox{
		width: 100%;
		height: auto;
		padding: 1rem 3.5rem 4rem;
		.mainTitle{
			width: 100%;
			height: 1rem;
			font-size: 0.32rem;
			font-weight: 700;
			color: #202020;
			text-align: center;
		}
		.descTitle{
			width: 100%;
			height: auto;
			font-size: 0.18rem;
			color: #7F8284;
			margin: 0.2rem auto 0.5rem;
		}
		.sameFiveList{
			width: 100%;
			height: auto;
			.smaeTabBox{
				width: 100%;
				height: 0.66rem;
				display: flex;
				align-items: center;
				>div{
					width: 12.5%;
					height: 0.66rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #828384;
					justify-content: center;
				}
				>div:first-child{
					width: 30%;
					justify-content: flex-start;
				}
				>div:last-child{
					width: 20%;
					justify-content: flex-end;
					>img{
						width: 1.3rem;
						height: 0.5rem;
						display: block;
					}
			}
		}
		.smaeTabBoxLine{
			width: 100%;
			height: 0.66rem;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #EBEEF0;
			>div{
				width: 12.5%;
				height: 0.66rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.18rem;
				color: #292828;
			}
			>div:first-child{
				width: 30%;
				justify-content: flex-start;
			}
			>div:last-child{
				width: 20%;
				justify-content: flex-end;
				>img{
					width: 1.3rem;
					height: 0.5rem;
					display: block;
				}
			}
			.green{
				color: #1DC24C;
			}
			.red{
				color: #F52C2C;
			}
		}
	}	
}
@media only screen and (max-width:1080px){
	.twoBox{
		width: 100%;
		height: auto;
		padding: 1rem 5% 2.5rem;
	}
}	
</style>