<template>
	<div id="economicCalendar">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="economicBanner">
			<div class="economicBannerBox">
				<div class="indexTitle">{{ $t('marketThree.banner.title') }}</div>
				<div class="indexSubTitle">{{ $t('marketThree.banner.desc') }}</div>
				<div class="economicBannerButton">{{ $t('marketThree.banner.button') }}</div>
			</div>
		</div>
		<div class="economicCalendarWidget">
			<div id="economicCalendarWidget"></div>
		</div>		
		
		
		<!-- <img class="economicImg" src="@/assets/images/market/marketThree01.png"/> -->
	</div>
</template>

<script>
	export default {
		name: 'economicCalendar',
		data() {
			return {
				
			}
		},
		mounted() {

		},
		created() {
			// const script = document.createElement('script');
			// script.src = "https://www.tradays.com/c/js/widgets/calendar/widget.js?v=12";
			// document.head.appendChild(script);
			// console.log(this.markets[this.navIndex].six)；
			this.scriptFun();
		},
		
		methods: {
			scriptFun(){
				// 创建一个新的script标签
				const script = document.createElement('script');
				// script.async = true;
				script.type = 'text/javascript';
				script.src = 'https://www.tradays.com/c/js/widgets/calendar/widget.js?v=12';
				script.dataset.type = 'calendar-widget';
				var jsonScript = '{"width":"100%","height":"100%","mode":"2"}';
				script.text = jsonScript;
				// 将新创建的script标签添加到body中
				console.log(script.text)
				document.body.appendChild(script);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.economicImg{
		display: block;
		width: 12rem;
		height:auto;
		margin: 0.6rem auto 2.6rem;
	}
	.economicCalendarWidget{
		width: 12rem;
		height:7.11rem;
		margin: 0.6rem auto 2.6rem;
	}
	.economicBanner{
		width: 100%;
		height: 4.75rem;
		background-color: #F7F7F7;
		display: flex;
		justify-content: center;
		align-items: center;
		.economicBannerBox{
			width: 12rem;
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.economicBannerButton{
				width: 2.50rem;
				height: 0.70rem;
				background: #D51820;
				border-radius: 0.35rem;
				display: flex;
				justify-content: center;
				align-items:center;
				font-size: 0.2rem;
				color: #FFFFFF;
				margin-top:0.3rem;
				cursor: pointer;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.economicImg{
			display: block;
			width: 92%;
		}
		.economicCalendarWidget{
			width: 92%;
		}
	}
</style>