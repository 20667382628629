/**
 * ajax请求配置
 */
import axios from 'axios'
import {Message} from 'element-ui'
// import apiURL from './api.js'
//import Qs from 'qs'

// import cookie from '../../static/js/cookie.js'

// axios默认配置
// axios.defaults.timeout = 5000;   // 超时时间
// axios.defaults.baseURL = 'https://www.ychpro.com/website/public/';  // 默认地址
axios.defaults.baseURL = 'https://www.ychpro.com/api/website/public/';  // 默认地址
//整理数据
axios.defaults.transformRequest = function (data) {
//data = Qs.stringify(data);
data = JSON.stringify(data);
  return data;
};

// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
  config => {
    //config.data = JSON.stringify(config.data);  
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
	config.headers['Content-Type'] = 'application/json;charset=UTF-8;';
    //判断是否存在ticket，如果存在的话，则每个http header都加上ticket
    if (localStorage.getItem('token')) {
        //用户每次操作，都将cookie设置成2小时　　 
		config.headers.token = localStorage.getItem('token');　　　　 
    }
    
    return config;
  },
  error => {
    return Promise.reject(error.response);
  });

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  response => {
	  console.log(response)
    if (response.data.code === 500) {
        console.log("response.data.code是500")
        // 返回 错误代码-1 清除ticket信息并跳转到登录页面
//      cookie.del("ticket")
//      window.location.href='http://login.com'
// 　　		this.$message({
// 		  message: response.data.msg,
// 		  type: 'success',
// 		  duration:3000
// 		});
		Message.error(response.data.msg)
        return false;
    }else if (response.data.code === 200) {
	     return response.data;
	  }
  },
  error => {
	  console.log(error)
	  // Message.error('错误')
	  Message.error(error.data.msg)
    return Promise.reject(error.response)   // 返回接口返回的错误信息
  });
export default axios;