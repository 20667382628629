<template>
	<div id="activity">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- 板块3 -->
		<div class="activityThree" v-if="mainJson.block1">
			<div class="plateThreeBox">
				<img class="leftImg" :src="mainJson.block1.img"/>
				<div class="plateBox">
					<div class="plateTitle" v-html="mainJson.block1.title"></div>
					<div class="samePlate display" v-for="(item,index) in mainJson.block1.desc" :key="index">
						<i class="samePlatedrop"></i>
						<span>{{item}}</span>
					</div>
					<div @click="openAccount(1)" class="plateMore discen">
						<span>{{ $t('activity.banner.button') }}</span>
						<img class="moreImg" src="@/assets/images/img/more.png"/>
					</div>
				</div>
			</div>
		</div>
		<div class="activityBox" v-if="mainJson.block2">
			<div class="indexTitle" v-html="mainJson.block2.title"></div>
			<div class="activityType">
				<div v-for="(item,index) in mainJson.block2.list" :key="index">{{item.title}}</div>
			</div>
			<div class="activityList">
				<div v-for="(item,index) in mainJson.block2.list" :key="index">{{item.desc}}</div>
			</div>
			<div class="activityBoxDesc" v-html="mainJson.block2.desc"></div>
			<div class="activityproblemList" v-for="(item,index) in mainJson.block3" :key="index">
				<div v-html="item.title"></div>
				<div v-html="item.desc"></div>
			</div>
		</div>
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import Tips  from '../components/layout/tips';
	export default {
		name: 'activity',
		components:{
			Tips
		},
		data() {
			return {
				navIndex:1,
				clickCcountAll:false,
				mainJson:{}
				
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=activity.json':'getJsonConfig?fileName=activity_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block2.list.length>0&&configData.block2.list[0].hasOwnProperty('sortNo')){
						configData.block2.list =  (configData.block2.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block3.length>0&&configData.block3[0].hasOwnProperty('sortNo')){
						configData.block3 =  (configData.block3).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.activityBox{
		width: 12rem;
		height: auto;
		margin: 0 auto 2.6rem;
		padding: 0 0.3rem;
		.activityType{
			width: 100%;
			height: 0.66rem;
			background-color: #ECEFF2;
			display: flex;
			align-items: center;
			margin-top: 0.44rem;
			>div{
				width: 33.3%;
				height: 0.66rem;
				padding-left: 0.4rem;
				font-size: 0.2rem;
				color: #202020;
				line-height: 0.66rem;
			}
		}
		.activityList{
			width: 100%;
			height: 0.66rem;
			border-bottom: 1px solid #E4E4E4;
			display: flex;
			align-items: center;
			>div{
				width: 33.3%;
				height: 0.66rem;
				padding-left: 0.4rem;
				font-size: 0.16rem;
				color: #202020;
				line-height: 0.66rem;
			}
		}
		.activityBoxDesc{
			font-size: 0.16rem;
			color: #7F8284;
			line-height: 0.3rem;
			margin-top: 0.28rem;
		}
		.activityproblemList{
			width: 100%;
			height: auto;
			margin: 0.5rem auto 0;
			font-size: 0.16rem;
			color: #7F8284;
			line-height: 0.3rem;
			>div:first-child{
				font-size: 0.2rem;
				line-height: 0.35rem;
				color: #202020;
				margin-bottom: 0.2rem;
			}
		}
	}
	.activityThree{
		width: 100%;
		height: auto;
		padding: 1rem 0.5rem;
		.plateThreeBox{
			width: 12rem;
			height: auto;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.leftImg{
				width: 5.2rem;
				height: 3.6rem;
			}
			.plateBox{
				flex: 1;
				height: auto;
				padding: 0 0 0 0.65rem;
				display: flex;
				flex-direction: column;
				justify-content:center;
				.plateTitle{
					width: 100%;
					height: auto;
					font-size: 0.32rem;
					color: #202020;
					font-weight: 500;
					line-height: 0.4rem;
					margin-bottom: 0.2rem;
				}
				.samePlate{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #202020;
					margin-bottom: 0.12rem;
					.samePlatedrop{
						width: 0.06rem;
						height: 0.06rem;
						background: #D51820;
						border-radius: 50%;
						margin-right: 0.05rem;
					}
				}
				.plateMore{
					width: 2.2rem;
					height: 0.46rem;
					background: #D51820;
					border-radius: 0.25rem;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin-top:0.3rem;
					padding: 0 0.2rem;
					cursor: pointer;
					.moreImg{
						width: 0.12rem;
						height: 0.12rem;
						margin-left: 0.05rem;
					}
				}
			}
		}
		
	}
	@media only screen and (max-width:1080px){
		.activityThree{
			padding:0.5rem 0.3rem;
			.plateThreeBox{
				width: 100%;
				height: auto;
				flex-direction: column;
				.leftImg{
					display: block;
					width:100%;
					height: auto;
					margin:0 auto 0.5rem;
				}
				.plateBox{
					padding: 0;
					.plateMore{
						width: 3rem;
						height: 0.5rem;
						margin: 0.5rem auto;
					}
				}
			}
			
		}
		
		.activityBox{
			width: 92%;
			.activityType{
				width: 100%;
				height: 0.9rem;
				>div{
					height:auto;
					padding-left: 0.2rem;
					line-height: 0.35rem;
				}
			}
			.activityList{
				width: 100%;
				height: 0.9rem;
				>div{
					width: 33.3%;
					height: auto;
					padding-left: 0.2rem;
					line-height: 0.35rem;
				}
			}
		}
		
		
	}
</style>