import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './assets/language/index.js'


import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element);

import tools from './utils/tools.js'
Vue.use(tools)

import axios from './utils/http.js'
Vue.prototype.$http = axios

import config  from './utils/config.js'
Vue.prototype.$apiBaseUrl = config.baseApi;

import VueAliplayerV2 from "vue-aliplayer-v2";

Vue.use(VueAliplayerV2);


Vue.config.productionTip = false

Vue.prototype.openAccountAll = function (type){
	// type:1 开户   2合作伙伴注册
 //  if(localStorage.getItem('token')){
	// console.log(1111111111)
	// this.clickCcountAll = true;
 //  }else{
 //  	this.$message({
 //  	  message: this.$t('element.logon'),
 //  	  type: 'error',
 //  	  duration:3000
 //  	});
 //  }
 axios.get(this.$apiBaseUrl+'getCommonConfig?key=account_opening_address').then((res)=>{
	// 在当前页面打开一个新的浏览器标签或窗口
	window.open(res.data.svalue, '_blank');
 }).catch((err)=>{
    console.log(err) 
    // loading.close();
 })
 
}
router.afterEach((to,from,next)=>{
    window.scrollTo(0,0);
})



new Vue({
  router,
  i18n,
  created () {
    AOS.init({
      duration: 800,
      easing: 'slide',
      once: true
    });
  },
  render: h => h(App)
}).$mount('#app')
