module.exports =  {
	element:{
		inReview:"Under review",
		logon:"please log in first",
		confirm:"Confirm",
		cancel:"Cancel",
		prompt:'prompt',
		cookie:'We use a number of cookies on this website for functional, analytical and marketing purposes. By continuing to use this website, you agree to our use of cookies. ',
		loginTips:'Login signifies agreement',
		registerTips:'Registration represents consent',
		word1:'《Disclosure Statement》',
		word2:'《Privacy Policy》',
		word3:'《Terms and Conditions》',
		prevPage:'prev page',
		nextPage:'next page',
		newsList:'news list',
		marketsBannerTitle:'Open A Live Account',
		marketsThreeTitle:'Open A Live Account',
		learnMore:'Learn More',
		author:'Teacher'
	},
    login: {
        signUp:"Sign up",
        signIn: "Sign in",
		signOut:"Sign out",
		submit:'Submit',
		tips:'Account review in progress',
		signtips:'Confirm logout?'
    },
	aboutAccount:{
		title:'Trade without <br/> limits',
		desc:'Transcend imagination and experience extraordinary trading pleasure',
		accountNumber:'Email Address',
		code:'Verification code',
		noAccount:'Not a cliet yet？',
		account:'Existing account？',
		password:'Password',
		confirmPassword:"Confirm Password",
		forget:'Forget your password',
		resend:'resend',
		send:'send',
		resetPassword:'Reset password',
		accountNumberNew:"Account Number"
	},
	language:{
		en:'English',
		zh:'Chinese',
		home:'home'
	},
	risk:{
		title:"Risk Disclosure",
		subDesc:"Risk Disclosure:",
		time:"6 November 2022",
		subTitle:"Please ensure that you read our detailed Risk Disclosure in full and understand its contents prior your forex and CFDs trading.",
		desc0:"YCHpro Securities Co Ltd has a registration with the Financial Services Commission (FSC) of Mauritius under the Securities Act 2005, the Securities (Licensing) Rules 2007, the Financial Services (Consolidated Licenses and Fees) Rules 2008, License No. GB23202152 and Company Registration No. 199660.",
		desc:"YCHpro's website and services are strictly prohibited from being open to any individual prohibited by national/regional laws and regulations. Therefore, if you choose to access our company's website from that country, regardless of whether the country prohibits the use of this website or not, users are responsible for ensuring that they comply with local laws or regulations when using this website. YCHpro does not guarantee that the information on our company's website is applicable to all jurisdictions.<br/><br/>Restricted Areas: YCHpro is unable to provide services to residents of specific countries/regions, such as the United States, Iran, and North Korea."
	},
	// {id:3,title:'Research & Education',src:'/marketingResearch',list:[{id:1,title:'Marketing Research',src:'/marketingResearch'},{id:2,title:'Trading Signal Analysis',src:'/tradingSignalAnalysis'},{id:3,title:'Economic Calendar',src:'/economicCalendar'}]},
	// {id:4,title:'Community',src:'/activity',list:[{id:1,title:'Activities',src:'/activity'},{id:2,title:'YCHpro Academy',src:'/college'},{id:3,title:'Live Courses'},{id:4,title:'Educational videos'}]},

	nav:[
		{id:1,title:'Markets',src:'/markets',list:[{id:1,title:'Forex'},{id:2,title:'Indices'},{id:3,title:'Commodities'},{id:5,title:'Metals'},]},
		{id:2,title:'Trading Platforms',src:'/tradingPlatform',list:[]},
		{id:3,title:'ResearchInstitute',src:'/ResearchInstitute',list:[{id:1,title:'Marketing Research',src:'/marketingResearch'},{id:2,title:'Trading Signal Analysis',src:'/tradingSignalAnalysis'},{id:3,title:'Economic Calendar',src:'/economicCalendar'},{id:4,title:'Educational videos',src:'/college'},{id:5,title:'Live Courses',src:''}]},
		{id:4,title:'Activities',src:'/activity',list:[]},
		{id:5,title:'Partners',src:'/partners',list:[]},
		{id:6,title:'Support',src:'/openAccount',list:[{id:1,title:'Open Account'},{id:2,title:'Deposit& Cash Back',src:'/access'}]},
		{id:7,title:'About YCHpro',src:'/aboutUs',list:[{id:1,title:'About Us'},{id:2,title:'Advantages'},{id:3,title:'FAQ',src:'/problem'},{id:4,title:'Contact Us',src:'/contactUs'}]},
	],
	banner:[
		{id:1,text:'More thoughtful service,more convenient trading<br/> all at YCHpro'},
		{id:2,text:'One platform <br/> with unlimited opportunities'},
		{id:3,text:'Growing together with professional knowledge <br/> sharing and world leading trading <br/> social platforms'}
	],
	bannerButton:{
		left:'Open an Account Now',
		right:'Register a partner account',
		center:'View Live Stream'
	},
	webData:[
		{id:1,text:'500+',name:'Different Tradings'},
		{id:2,text:'0.0',name:'Point difference'},
		{id:3,text:'1000:1',name:'Leverage up'}
	],
	plateTwo:{title:'Transcend imagination and experience extraordinary trading pleasure',list:[
		{id:1,text:'Simple and fast operation interface'},
		{id:2,text:'Trading by fingertips'},
		{id:3,text:'Multiple technical charts'},
		{id:4,text:'Enable a better trading experience'},
		],more:'Learn more'},
	support:{
		title:'Trading support for Browser/PC client/mobile terminal',
		windows:'Windows',
		andriod:'Andriod',
		ios:'Ios'
	},
	plateThree:{
		title:'Unlimited opportunities in the global market',
		list:[
			{id:1,text:'Forex ',src:''},
			{id:2,text:'Indices ',src:''},
			{id:3,text:'Commodities ',src:''},
			{id:4,text:'Metals ',src:''},
			{id:5,text:'Energy ',src:''},
			{id:6,text:'Stocks ',src:''},
		]
	},
	plateFour:{
		title:'Hottest Tradings',
		list:[
			{id:1,title:'Price and execution advantages',text:'Provides very competitive and stable, transparent low spreads, overnight interest rates, and excellent trade execution.'},
			{id:2,title:'Trading tools and platforms',text:'Excellent trading platform, rich mobile applications, quality third-party trading tools and market analysis.'},
			{id:3,title:'Account Advantage',text:'Strictly protect client funds, convenient deposits and withdrawals, one-on-one market strategists, and quality follow-up services.'},
			{id:4,title:'500+ Tradings',text:'Forex, precious metals, crude oil, global hot stocks, stock indexes, futures and other CFDs.'},
		]
	},
	plateFive:{
		title:'Trade without limits  Unlock Unlimited Possibilities',
		desc:"Trading accounts tailored to fit your needs and goals – whether you're a beginner or an experienced trader",
		subtitle:'How to upgrade an account',
		subDesc:'Your account type will be depended by your initial funds when you open an account. To learn more about how to upgrade,',
		customerManager:'please contact our customer manager',
		list:[
			{id:1,title:'Micro Account',price:'100',limitation:'Initial Deposit',desc:'Enjoy a lower opening deposit',list:[
				{id:1,title:'Low opening deposit'},
				{id:2,title:'First-class online service and support'},
				{id:3,title:'24/5 technical and account support services'},
				],
			},
			{id:2,title:'Standard Account',price:'500',limitation:'Initial Deposit',desc:'Enjoy a lower margin and commission',list:[
				{id:1,title:'Low opening deposit'},
				{id:2,title:'First-class online service and support'},
				{id:3,title:'More favorable prices than regular user'},
				{id:4,title:' Free to contact our trading experts directly at any time'},
				],
			},
			{id:3,title:'VIP Account',price:'3000',limitation:'Initial Deposit',desc:'Enjoy the best prices and services',list:[
				{id:1,title:'Low opening deposit'},
				{id:2,title:'First-class online service and support'},
				{id:3,title:'More favorable prices than regular users'},
				{id:4,title:'Free to contact our trading experts directly at any time'},
				{id:5,title:'One-on-one service from YCHpro’s experts'},
				],
			}
		]
	},
	plateSix:{
		title:'Why choose YCHpro?',
		list:[
			{id:1,title:'Multi-assets platform',text:'Stocks, forex, commodities, and more'},
			{id:2,title:'Enhance your trading capabilities through YCHpro',text:"YCHpro's product developers are not only technical experts, but also experienced traders involved in trading <br/>Whenever you buy or sell, you will accurately know the risks involved "},
			{id:3,title:'Trading signals',text:'Using big data to analyze past trends and identify the best trading opportunities available'},
		]
	},
	plateSeven:{
		title:'With YCHpro, you will not be limited to one Forex and CFDs trading account <br/>More services to assist you ',
		list:[
			{id:1,title:'Latest Market Research',text:'Analyzing major news and events in the international economic market, helping you seize every opportunity in the market'},
			{id:2,title:'Daily live online',text:'Live trading 10 hours a day, receive hot product trading signals online, master trading skills'},
			{id:3,title:'Real time trading signals checking',text:'Real time trading strategies help you understand the latest market trends and better grasp trading opportunities'},
			{id:4,title:'24/7 Online customer service',text:'You can contact us anytime, anywhere, to provide you with customized market analysis push, instant access to the latest offers'},
			{id:5,title:'Trading knowledge',text:'YCHpro works with you to learn and grow, and improve trading skills'},
			{id:6,title:'Economic Calendar',text:'For more service details, please sign in to check customer section'}
		]
	},
	plateEight:[
		{id:1,title:'Customer Devoted',text:'We do not care about the size of your funds. Customer first is the only principle we adhere to, not the type of funds, account, or investment size. All of our customers will accept the same execution speed and customer support. The values that YCHpro adhered to at its inception will not change.'},
		{id:2,title:'Scope of trading',text:'You can use one platform to trade multiple products, and YCHpro makes trading simpler and more effective.'},
		{id:3,title:'Transparency and fairness',text:'YCHpro does not have any hidden terms at the price, execution, and bonus you see.'}
	],
	plateNine:{
		title:'YCHpro Partner Program,Recommending customers to leading global market platforms and earn stable income',list:[
			{id:1,text:'Our commission rewards are generous and regularly paid that highly competitive in the market'},
			],joinNow:'Join now'
	},
	contactUs:{
		title:'Contact us',
		desc:'You can contact us anytime the markets are open, we’re here 24 hours a day 5 days a week.',
		submit:'Submit',
		tips:"Please enter",
		tipss:"Please select",
		list:[
			{id:1,type:'text',title:'First Name',value:''},
			{id:2,type:'text',title:'Last Name',value:''},
			{id:3,type:'text',title:'Email Address',value:''},
			{id:4,type:'text',title:'Confirm Email Address',value:''},
			{id:5,type:'select',title:'Country',value:''},
			{id:6,type:'text',title:'Phone number',value:''}
		]
	},
	footer:[
		{id:1,title:'Tradings',src:'/markets',list:[
			{id:1,text:'Forex'},
			{id:2,text:'Indices'},
			{id:3,text:'Commodities'},
			{id:4,text:'Metals'}
		]},
		{id:2,title:'Platforms',src:'/tradingPlatform',list:[
			{id:1,text:'MT5'}
		]},
		{id:3,title:'Account',src:'/openAccount',list:[
			{id:1,text:'Micro Account'},
			{id:2,text:'Standard Account'},
			{id:3,text:'VIP Account'}
		]},
		{id:4,title:'Customer Support',src:'/openAccount',list:[
			{id:1,text:'Deposit& Cash Back',src:'/access'},
			{id:2,text:'Leverage and margin'},
			{id:3,text:'Trading Times'}
		]},
		{id:5,title:'Partners',src:'/partners',list:[
			{id:1,text:'Introducing Brokers'}
		]},
		{id:6,title:'About US',src:'/aboutUs',list:[
			{id:1,text:'YCHpro Introduction',src:'/aboutUs'},
			{id:2,text:'Contact US',src:'/contactUs'},
			{id:3,text:'Execution Policy',src:'/risk?id=4'},
			{id:4,text:'Privacy Policy',src:'/risk?id=2'}
		]}
	],
	marketTab:[
		{id:0,title:"Range of Forex Pairs",desc:"*Average spread is calculated based on the average of the historical spread. Spreads are variable",list:[
			{id:1,title:'Major FX Pairs',list:[
				{id:0,title:'Currency Pair',num:'Average Spread*'},
				{id:1,title:'AUDUSD',num:'0.1'},
				{id:2,title:'USDCAD',num:'0.2'},
				{id:3,title:'EURUSD',num:'0.1'},
				{id:4,title:'USDJPY',num:'0.2'},
				{id:5,title:'USDCHF',num:'0.3'},
				{id:6,title:'GBPUSD',num:'0.3'}]},
			{id:2,title:'Minor FX Pairs',list:[
				{id:0,title:'Forex Pairs',num:''},
				{id:1,title:'AUDCAD',num:'AUDJPY'},
				{id:2,title:'USDSGD',num:'CHFJPY'},
				{id:3,title:'EURCAD',num:'EURGBP'},
				{id:4,title:'EURNZD',num:'GBPCAD'},
				{id:5,title:'GBPJPY',num:'NZDUSD'},
				{id:6,title:'AUDCHF',num:'AUDNZD'},
				{id:7,title:'CADJPY',num:'EURAUD'},
				{id:8,title:'EURCHF',num:'EURJPY'},
				{id:9,title:'GBPAUD',num:'GBPCHF'},
				{id:10,title:'GBPNZD',num:'NZDJPY'},
			]},
			{id:3,title:'Exotic FX Pairs',list:[
				{id:0,title:'Forex Pairs',num:''},
				{id:1,title:'AUDHKD',num:'USDTRY'},
				{id:2,title:'EURMXN',num:'USDZAR'},
				{id:3,title:'EURTRY',num:'EURDKK'},
				{id:4,title:'USDCNH',num:'EURHUF'},
				{id:5,title:'USDDKK',num:'EURNOK'},
				{id:6,title:'USDHKD',num:'EURPLN'},
				{id:7,title:'USDMXN',num:'EURSEK'},
				{id:8,title:'USDNOK',num:'USDHUF'},
				{id:9,title:'USDSEK',num:'USDPLN'},
				{id:10,title:'AUDCNH',num:'CADCHF'},
				{id:11,title:'NZDCAD',num:'NZDCHF'},
				{id:12,title:'AUDSGD',num:''}
			]},
			{id:4,title:'Metal Pairs',list:[
				{id:0,title:'Metal Pairs',num:''},
				{id:1,title:'XAGUSD',num:''},
				{id:2,title:'XAUUSD',num:''},
			]}
		]},
		{id:1,title:"Range of Indices to Trade",list:[
			{id:1,title:'Cash Index CFDs',desc:'Below is the full list of the global lndices you get access to via our MetaTrader 4 lndices trading platform. Please note that all Indices offered are provided as a CFD.',list:[
				{id:0,one:'Instrument',two:'MT4 Symbol',three:'Contract Size',four:'Margin',five:'Currency',six:'Min. Trade Size',seven:'Max. Trade Size'},
				{id:1,one:'FTSE 100',two:'FTSE100',three:'£1 x symbol value',four:'1.00%',five:'GBP',six:'0.1',seven:'250'},
				{id:2,one:'DAX 30',two:'DAX30',three:'€1 x symbol value',four:'1.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:3,one:'ESP 35',two:'ESP35',three:'€1 x symbol value',four:'2.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:4,one:'CAC 40',two:'CAC40',three:'€1 x symbol value',four:'2.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:5,one:'STOXX 50',two:'STOXX50',three:'€1 x symbol value',four:'1.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:6,one:'WS 30',two:'WS30',three:'€1 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'250'},
				{id:7,one:'US 500',two:'US500',three:'$10 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'50'},
				{id:8,one:'US 2000',two:'US2000',three:'$10 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'50'},
				{id:9,one:'NDX 100',two:'NDX100',three:'$1 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'250'},
				{id:10,one:'ASX 200',two:'ASX200',three:'$1 x symbol value',four:'1.00%',five:'AUD',six:'0.1',seven:'250'},
				{id:11,one:'HK 50',two:'HK50',three:'HK$10 x symbol value',four:'4.00%',five:'HKD',six:'0.1',seven:'100'},
				{id:12,one:'JP 225',two:'JP225',three:'¥100 x symbol value',four:'1.00%',five:'JPY',six:'0.1',seven:'100'},
			]},
			{id:2,title:'Futures Index CFDs',desc:'GO Markets will automatically roll any open positions in Futures CFD contracts which will result in paying the spread (value of ASK – BID price) upon the roll. The rollover arises when the underlying instrument associated with a CFD is due for expiry and GO Markets begins to price the CFD from the next available futures contract. As the next dated futures contract trades at either a discount or a premium to the expiring futures contract, your trading account will be credited or debited the difference between the closing price of the expiring contract and the opening price of the new contract, depending on your net exposure of the rolled instrument. GO Markets will generally roll futures contracts within 72 business hours of the current contract expiry date in order to avoid low liquidity and larger spreads as the current futures contract approaches expiry.',list:[
				{id:0,one:'Instrument',two:'MT4 Symbol',three:'Contract Size',four:'Margin',five:'Currency',six:'Min. Trade Size',seven:'Max. Trade Size',eight:'Example of Tick Value'},
				{id:1,one:'China 50',two:'CHINA 50',three:'$1x symbol value',four:'5%',five:'USD',six:'0.1',seven:'50',eight:'10055>10056'},
				{id:2,one:'US Dollar',two:'USDOLLAR',three:'$1000x symbol value',four:'1%',five:'USD',six:'0.1',seven:'50',eight:'101.305>101.315'},
			]},
		]},
		{id:2,title:"Range of Commodities",list:[
			{id:1,title:'All Instruments',desc:'Below is the full range of commodity CFDs you can trade via our MetaTrader 4 & MetaTrader 5 trading platforms:',list:[
				{id:0,one:'Instrument',two:'Symbol',three:'Spread',four:'Lot Size',five:'Trading Hours (Platform time)'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F',three:'0.004',four:'25.000 1bs',five:'01:00-24:00'},
				{id:4,one:'Spot WTI Crude Oil',two:'USO/USD',three:'Variable',four:'100 Barrels',five:'01:00-24:00'},
				{id:5,one:'Spot Brent Crude Oil',two:'USO/USD',three:'Variable',four:'100 Barrels',five:'03:00-24:00'},
				{id:6,one:'US Oil Futures',two:'USOILE',three:'Variable',four:'100 Barrels',five:'01:00-24:00'},
				{id:7,one:'US Oil Futures',two:'UKOiLF',three:'Variable',four:'100 Barrels',five:'03:00-24:00'},
				{id:8,one:'Soybean Futures',two:'SBEAN-F',three:'Variable',four:'10,000 bushels',five:'03:00-15:45 16:30-21:20'},
				{id:9,one:'Wheat Futures',two:'WHEATF',three:'Variable',four:'10,000 bushels',five:'3:00-15:45 16:30-21:21'},
				{id:10,one:'Spot Natural Gas',two:'NGAS',three:'0.013',four:'10,000 MMBtu',five:'01:00-24:00'},
			]},

		]},
		{id:3,title:"Range of Commodities",list:[
			{id:1,title:'All Instruments',desc:'Below are the metal commodity CFDs you can trade via our MetaTrader platforms:',list:[
				{id:0,one:'Instrument',two:'Symbol',three:'Spread',four:'Lot Size',five:'Trading Hours (Platform time)'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F0.004',three:'0.004',four:'25000 unit',five:'01:00-24:00'},
			]},
		]},
		{id:4,title:"Range of Commodities",list:[
			{id:1,title:'All Instruments',desc:'Below are the metal commodity CFDs you can trade via our MetaTrader platforms:',list:[
				{id:0,one:'Instrument',two:'Symbol',three:'Spread',four:'Lot Size',five:'Trading Hours (Platform time)'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F0.004',three:'0.004',four:'25000 unit',five:'01:00-24:00'},
			]},
		]}
	],
	markets:[
		{id:1,title:'Forex',button:'Open A Live Account',
		banner:{
			title:'Best dealer of online forex trading platforms',
			desc:'Forex brings the opportunity of the world’s largest, most liquid market, with an average daily trading volume of over $8 trillion US dollars.<br/> You can access main currency pairs and get the tools and supports you need to access the worlds largest market by YCHpro.',
			notReady:'Not ready yet?'
			},
		two:{
			title:'Why trade forex CFDs with YCHpro?',
			desc:'Forex trading provides a wealth of potential opportunities for experienced traders and market novices. <br/>If you are looking for a diverse and active trading environment, forex is your best choice.',
			list:[
				{id:1,title:'24/5 hour trading',desc:'The Forex market trades 24 hours a day without the restrictions of specific trading hours like the stock market.'},
				{id:2,title:'You can either go long or go short',desc:'You can trade both ways, even in a falling market, you can still find profit opportunities by shorting.'},
				{id:3,title:'Flexible leverage',desc:'Provide flexible leverage, you can establish a position that is worth a hundred times or even a thousand times.'},
				{id:4,title:'Smaller trading CFDs',desc:'YCHpro can trade at least 0.01 lots, which greatly reduces the threshold for investment.'},
				{id:5,title:'A concise and clear platform',desc:'The powerful MT5 platform integrates market quotations, tradings, account management, and informations.'},
				{id:6,title:'Competitive transaction costs',desc:'0 trading commission, transparent low price difference, low overnight interest.'}
			]
		},
		three:{
			title:'Internet technology allows traders from around the world to trade directly with each other. In brief, <br/>forex is a market without intermediaries. Before participating in this incredible and fast-paced market, <br/>all you need to do is open a trading account through a reliable broker.',
			right:{
				title:'Open A Live Account',
				list:[
					{id:1,title:'Top level liquidity and fast execution speed'},
					{id:2,title:'One click links to over 500 different global tradings'},
					{id:3,title:'All account types, with a spread as low as 0'},
					{id:4,title:'Seamless-trading no matter where you are'}
				]
			}
		},
		four:{
			title:'Benefits of Forex Trading',
			list:[
				{id:1,title:'Multiple forex currency pairs',src:''},
				{id:2,title:'High Liquidity',src:''},
				{id:3,title:'Flexible leverage',src:''},
				{id:4,title:'24-hour trading',src:''},
				{id:5,title:'Low trading costs',src:''},
			]
		},
		six:[
			{id:1,title:'What is a forex currency pair?',desc:'In the forex market, trading involves buying or selling between two different currencies. These currency combinations are called currency pairs. Exchange rate is the relative price of currencies compared to each other. There are seven major currency pairs that all include the US dollar.',show:false},
			{id:2,title:'How to make money through forex trading?',desc:'International currency trading provides unparalleled opportunities for participants. By the depth and liquidity of forex, almost any feasible strategy can be implemented with the highest efficiency. From real-time trading signals in short-term trading to trend band trading plans, profit from cyclical exchange rate fluctuations.<br/>Unlike traditional trading modules such as stocks, forex trading profits are not limited to buying low and selling high. You can make money from long or short positions in a specific market, thereby multiplying the number of potential trading opportunities.<br/>Whether your prospection for the currency is bullish or bearish, you can achieve profitability by buying low and selling high or selling high and buying low.',show:false},
			{id:3,title:'How to calculate your forex trading profit?',desc:'Forex quotations generally have five decimal places. The fourth decimal place is commonly referred to as the "pip". We often use pips to calculate profits and losses.<br/>Gain and loss can be visually displayed in the Metatrader 5 software system of the trading platform. As the market changes, your account balance will be automatically updated through the functionality of your platform.',show:false},
			{id:4,title:'How much money do you need to get started?',desc:'One of the greatest advantages of forex trading is the minimal amount of capital required to get started. YCHpro offers interested individuals the opportunity to start trading with as little as $100.',show:false},
			{id:5,title:'What factors will affect forex prices?',desc:'In the long run, trading forex means trading the economic conditions between two different countries. The better the economy of a country, the more it will attract international capital inflows, and the currency of the country will appreciate. In reality, the factors affecting foreign exchange prices are very complicated. Generally speaking, there are three important influencing factors: ',list:[
				{id:1,title:'Economic data',desc:'The economic situation of a country is reflected through economic data, so when important economic data is announced, it often has a major impact on the relevant currency pairs. For example, the most well-known U.S. Non-farm Payrolls data, because it reflects the employment situation in the United States, will have an important impact on the trend of dollar-related currency pairs.'},
				{id:2,title:'Central bank monetary policy',desc:'When a countrys central bank tightens monetary policy (raises interest rates), it has a positive effect on the countrys currency. Because global capital is profit-seeking, funds will naturally flow from the currencies of low-interest countries to the currencies of high-interest countries, thereby promoting the appreciation of high-interest international currencies.'},
				{id:3,title:' Geopolitics',desc:'Geopolitics can also have a significant impact on the forex market, such as the recent war between Russia and Ukraine, which has led to a surge in global risk aversion and various factors driving significant fluctuations in the US dollar index.'}
			],show:false}
		]
		},
		{id:2,title:'Indices',button:'Open A Live Account',
		banner:{
			title:'Indices',
			desc:'Index trading allows you to trade global stock market trends without directly owning specific stocks. You only need to <br/>trade based on the price changes of the index.',
			notReady:'Not ready yet?'
			},
		two:{
			title:'Why trade index CFDs with YCHpro?',
			desc:'',
			list:[
				{id:1,title:'Stock market exposure',desc:'It is a way to access the stock market of an economy or economic sector, instantly connect to the global economy, and connect with the global stock market.'},
				{id:2,title:'Economic window',desc:'For CFD traders, this provides an easy way to participate in trading main stocks in countries around the world, with instant exposure to entire industries or entire economies.'},
				{id:3,title:'Simple and clear platform',desc:'The powerful MT5 platform integrates market quotations, tradings, account management, and informations.'},
				{id:4,title:'Competitive trading costs',desc:'0 trading commission, transparent low price difference, low overnight interest.'},
				{id:5,title:'Low threshold trading conditions',desc:'The minimum trading lot is as low as 0.01 lot, and you can open a position with a minimum margin.'},
				{id:6,title:'High-level online customer service',desc:'24/5 online customer service, providing quality service.'}
			]
		},
		three:{
			title:'Index trading is becoming increasingly popular compared to traditional stock trading. Our index CFDs are designed <br/>to provide you with a wide range of global market access opportunities, enabling you to diversify your trading<br/> strategy on unrelated instruments, thereby reducing the risk of trading a companys stock alone.',
			right:{
				title:'Open A Live Account',
				list:[
					{id:1,title:'Top level liquidity and fast execution speed'},
					{id:2,title:'One click links to over 500 different global tradings'},
					{id:3,title:'All account types, with a spread as low as 0'},
					{id:4,title:'Seamless-trading no matter where you are'}
				]
			}
		},
		four:{
			title:'Benefits of index trading',
			list:[
				{id:1,title:'A set of stock portfolio data made at a price below the cost of trading alone',src:''},
				{id:2,title:'Reach an entire industry or economy at once',src:''},
				{id:3,title:'Hedging against declines in stock portfolio value',src:''}
			]
		},
		six:[
			{id:1,title:'What are indices?',desc:'An index is a measure of the performance of a group of stocks listed on an exchange and is used to track the performance of a countrys economy, an industry or a stock exchange. Since there is no underlying physical asset to exchange when trading an index, most index trading is done using financial derivatives such as CFDs. Is a great way to hedge other positions, with lower capital requirements when leveraged.',show:false},
			{id:2,title:'How to trade indices?',desc:'In indices trading, it is possible to buy in (Go Long), or can be sold (Go Short), and the use of index is an ideal hedging tool. Just an example, if you are optimistic about HSBCs future stock price, you purchase HSBCs stock. At the same time, you can short the FTSE 100 index in the UK to offset the impact of counter trend fluctuations in the market.',show:false},
			{id:3,title:'What are the main indices in the world?',desc:'The first stock price index was founded by Mr. Charles Dow in 1896 and was named the Dow Jones Index. Today, the Dow Jones index includes the stock prices of 30 of the largest companies in the United States. When the internet era arrived, various strange indices emerged. The criterion for becoming a major index is to see whether the companies it represents are well-known. The most traded stock indices in the world include the Dow Jones Industrial Average, S&P 500, FTSE, DAX in Germany, ASX200 in Australia, NASDAQ, CAC in France, and Nikkei 225.',show:false},

		]},
		{id:3,title:'Commodities',button:'Open A Live Account',
		banner:{
			title:'Commodity trading',
			desc:'Broaden horizons Grasp many trading opportunities',
			notReady:''
			},
		two:{
			title:'Why Commodity CFDS with YCHpro?',
			desc:'',
			list:[
				{id:1,title:'Super low spread',desc:'We offer you competitive spreads'},
				{id:2,title:'Low margin',desc:'Energy commodities can be traded in the international market with only a small deposit'},
				{id:3,title:'Advanced Trading Platform',desc:'We provide the popular new MT5 platform, integrating market, trading, account management, information and risk management'},
				{id:4,title:'Portfolio diversification',desc:'Multiple varieties can be cross-configured to reduce risk.'},
				{id:5,title:'24/5 exclusive customer support',desc:'Each customer has been assigned a personal account manager'},
				{id:6,title:'Trade long and short',desc:'You can take long or short positions on commodities as you only trade the price movements.'}
			]
		},
		three:{
			title:'Among commodities, crude oil is one of the most popular markets to trade in, so much so that many traders have nicknamed it "black gold."<br/>Its high fluctuation enables short-term traders to quickly find potential trading opportunities in the market.<br/>With YCHpro you can easily trade commodities online, always maintaining the same low spreads and competitive trading conditions!',
			right:{
				title:'Open A Live Account',
				list:[
					{id:1,title:'Top level liquidity and fast execution speed'},
					{id:2,title:'One click links to over 500 different global tradings'},
					{id:3,title:'All account types, with a spread as low as 0'},
					{id:4,title:'Seamless-trading no matter where you are'}
				]
			}
		},
		four:{
			title:'Benefits of Energy Trading',
			list:[
				{id:1,title:'Find opportunities in the worlds largest commodities market',src:''},
				{id:2,title:'Irreplaceable key element of the global economy',src:''},
				{id:3,title:'Highly liquid market, it is easier to enter and exit trading at any time',src:''},
				{id:4,title:'Resisting risks in geopolitical tensions',src:''}
			]
		},
		six:[
			{id:1,title:'What are Commodities?',desc:'A commodity is a resource, not just a traded product. They can be divided into two categories namely hard commodities and soft commodities.<br/>Hard commodities are things that can be extracted or developed, such as metals and energy; while soft commodities are natural resources, such as agricultural products that can be grown.<br/>You can trade commodities just like any other financial product. You can choose to trade hard commodities such as gold, oil or platinum; or you can trade futures on soft commodities such as wheat, corn and soybeans.<br/>Commodities can be a great way to diversify your portfolio.',show:false},
			{id:2,title:'What are the factors affecting commodities?',desc:'',list:[
				{id:1,title:'',desc:'Supply and Demand: All markets are driven by the dynamics of supply and demand for buying and selling assets. In commodity markets, however, the situation is more pronounced. A large increase (or decrease) in demand for any particular commodity can cause a significant increase (or decrease) in the price of that commodity. Instead, changes in commodity supply dynamics will have the opposite effect.'},
				{id:2,title:'',desc:'USD: Most commodities are quoted and traded in USD. This means that changes in the value of the U.S. dollar against other currencies affect commodity prices. In general (although this relationship may change), there is an inverse relationship between the value of the dollar and the price level of commodities.'},
				{id:3,title:'',desc:'The state of the global economy: The health of the global economy can have a major impact on the overall demand and supply of various commodities, thereby affecting prices.'},
				{id:4,title:'',desc:'Energy is an inherently fluctuant market, often due to world events that have a direct impact on supply. Many political and environmental factors can affect energy prices and thus supply and demand, including economic growth, political unrest in oil and gas producing countries, weather forecasts and extreme weather conditions and government regulations.'}
			],show:false},

		]},
		{id:4,title:'Stocks'},
		{id:5,title:'Metals',button:'Open A Live Account',
		banner:{
			title:'One-stop trading of gold, silver, platinum and other precious metals',
			desc:'Trade metal products such as gold and silver at the most competitive cost',
			notReady:''
			},
		two:{
			title:'Why trade precious metals CFDs with YCHpro?',
			desc:'',
			list:[
				{id:1,title:'Super low spread',desc:'We offer you competitive spreads'},
				{id:2,title:'Low margin',desc:'Gold and other precious metals can be traded in the international market with only a small amount of deposit.'},
				{id:3,title:'Advanced Trading Platform',desc:'We provide the popular new MT5 platform, integrating market, trading, account management, information and risk management。Portfolio diversification'},
				{id:4,title:'Portfolio diversification',desc:'Trading energy commodity products can diversify investment portfolios and provide greater flexibility, allowing for free trading without the need for actual assets'},
				{id:5,title:'No contract expiration date',desc:'No need to consider the issue of contract expiration, delivery, and extension, providing you with a more flexible trading experience.'},
				{id:6,title:'24/5 exclusive customer support',desc:'Each customer has been assigned a personal account manager'},
			]
		},
		three:{
			title:'Investing in precious metals can help traders hedge against market fluctuations, <br/>political uncertainty and economic collapse. It can also be an important part of a diversified long-term portfolio. <br/>With YCHpro you can easily trade gold and other precious metals online, always maintaining the same low spreads and competitive trading conditions!',
			right:{
				title:'Open A Live Account',
				list:[
					{id:1,title:'Top level liquidity and fast execution speed'},
					{id:2,title:'One click links to over 500 different global tradings'},
					{id:3,title:'All account types, with a spread as low as 0'},
					{id:4,title:'Seamless-trading no matter where you are'}
				]
			}
		},
		four:{
			title:'The Benefits of Precious Metal Trading',
			list:[
				{id:1,title:'Safe havens',desc:'Gold and silver are widely considered safe havens during times of market turmoil, helping you diversify or hedge against inflation.',src:''},
				{id:2,title:'Balanced portfolio',desc:'Trading or investing in gold and silver can make your overall portfolio more balanced.',src:''},
				{id:3,title:'Intrinsic Value',desc:'Unlike other investments such as gold bars, physical holdings can be held',src:''},
				{id:4,title:'Liquidity',desc:'With international mobility',src:''},

			]
		},
		six:[
			{id:1,title:'What are precious metals?',desc:'In the forex market, it includes gold and silver. Of these, gold is the most attractive and the most traded. However, several others, especially silver, also exhibit very high volatility, and as such, they can offer ample trading opportunities as well. Potentially, including precious metals in your portfolio not only increases investment diversification, but also most likely increases the long-term profitability of your portfolio.-',show:false},
			{id:2,title:'How to trade precious metals?',desc:'There are several factors that make trading precious metals, especially gold, so attractive. Chief among these is that fundamental forecasts for gold prices can be based on its inverse relationship to global economic trends. If the economic situation is going bad, investors will seek gold as a refuge -- and the price of gold will rise accordingly. If the global economy is booming, the optimism pushes traders to take more risks -- and gold prices typically fall. This is why the 2020 epidemic crisis can push the price of gold to an all-time high of more than $2,000!',show:false},
			{id:3,title:'What factors affect the price of precious metals?',desc:'',list:[
				{id:1,title:'Supply and demand',desc:'As demand increases, metal prices rise, and when demand weakens, the value of precious metals falls.'},
				{id:2,title:'Dollar value',desc:'Because precious metals are denominated in dollars, their prices are generally inversely related to their dollar value.'},
				{id:3,title:'Inflation',desc:'Inflation directly affects the price of precious metals. Typically, an increase in the rate of inflation tends to increase the price of precious metals.'},
				{id:4,title:'Industrial demand',desc:'Technical and industrial uses of gold, such as demand for jewelry and electronics, can drive up the price of the precious metal.'},
			],show:false},

		]},
	],
	aboutUs:{
		banner:{
			title:'Empowering the global investor community',
			desc:'Not only a mission, <br/>but also a commitment to customers',
			src:require("@/assets/images/about/about01.png")
		},
		about:{
			title:'About YCHpro',
			desc:'YCHpro is a world-leading online trading provider of financial products and one of the worlds leading social investment networks, <br/>providing traders with financial derivatives trading such as forex, precious metals, and indices. <br/>Provide round-the-clock retail and institutional services to Europe, Asia, Africa, Australia and other countries and regions. <br/>Always adhere to the customers needs as our core service principle, and strive to provide customers with the most intimate service.'
		},
		why:{
			title:'Why trade with YCHpro ',
			list:[
				{id:1,title:'Financial security',desc:'All client funds are deposited separately in top international banks. And committed to isolate and store customer funds from company operating funds, so that customer funds are always under protection.',src:require("@/assets/images/about/about02.png")},
				{id:2,title:'Trading Tools and Platforms',desc:"Excellent trading platform, rich mobile applications, high-quality third-party trading tools and market analysis.",src:require("@/assets/images/about/about03.png")},
				{id:3,title:'Rich trading products',desc:"Trade forex, gold, silver and other precious metals, global popular stocks, stock indexes and other CFDs.",src:require("@/assets/images/about/about04.png")},
				{id:4,title:'Deep liquidity',desc:"We have collected financial liquidity from multiple top banks to get the best price no matter the size of the trading.",src:require("@/assets/images/about/about05.png")},
				{id:5,title:'Excellent customer service',desc:"YCHpro is committed to providing excellent customer service and support to all clients, live chat will be available 24 hours a day, Monday to Friday.",src:require("@/assets/images/about/about06.png")},
				{id:6,title:'Global live broadcast in Chinese and English',desc:"Live broadcast in English and Chinese for 10 hours a day, market analysis and trading signals are pushed to ensure that you keep up with market dynamics and always create trading opportunities for you",src:require("@/assets/images/about/about08.png")}
			]
		},
		ourMission:{
			title:'Our Mission',
			desc:'YCHpro will always build a multi-asset investment platform based on <br/>social collaboration and investor education to empower users.'
		},
		ourVision:{
			title:'Our Vision',
			desc:"Join YCHpro to become a member of the global investor community, connect, share and learn <br/>with others on the world's leading social investment network.",
			src:require("@/assets/images/about/about07.png")
		},
		ourValues:{
			title:'Our Values',
			desc:"YCHpro's values are reflected in the products and user experience, also rooted in our internal culture and employee experience.",
			list:[
				{id:1,title:"Simple and convenient",desc:"We simplify the trading and investment process, and the platform is concise and clear, allowing traders to quickly and easily participate in trading and investment."},
				{id:1,title:"Innovative and enterprising",desc:"The establishment of YCHpro shoulders the mission of innovative financial development. We will provide new service methods to adapt to the constantly upgrading needs of users. We will continue to strive for progress with a user experience-centered way of thinking."},
				{id:1,title:"Global Shared Live Community",desc:"YVHpro is a financial services company and a social network. What users participate in is not only financial investment, but also a platform for exchanging knowledge, sharing experience, and expressing emotions and ideas."},
			]
		}
	},
	partner:{
		banner:{
			title:"Innovative Reliable Differential",
			subtitle:'Work with a trusted global broker',
			src:require("@/assets/images/partners/partners01.png")
		},
		two:{
			title:"Partnering Broker",
			desc:"YCHpro is able to provide competitive commissions, multifunctional live broadcast platform, trading tools and excellent customer support to all brokers. Build your client base while working with YCHpro to provide your clients with the best trading experience.",
			subtitle:"Work with YCHpro to get more than just a partner account",
			subdesc:"You will get",
			list:[
				{id:1,title:"Reliable back-office support and Technical training for network marketing promotion and customer acquisition",desc:"",src:require("@/assets/images/partners/partners02.png")},
				{id:2,title:"Customized Commission Structure",desc:"",src:require("@/assets/images/partners/partners03.png")},
				{id:3,title:"Industry-leading marketing live broadcast platform sharing",desc:"",src:require("@/assets/images/partners/partners04.png")},
				{id:4,title:"Business Development Training and Mentoring",desc:"",src:require("@/assets/images/partners/partners05.png")}
			]
		},
		three:{
			title:"Enjoy more, earn more.",
			desc:"Choose a fair and humane broker<br/>Attract as many clients as possible and unleash your full potential at YCHpro",
			src:"",
			subdesc:"If you want to join our program, please apply for account registration now",
			button:"Account registration"
		},
		four:{
			title:"We treat every customer as a preferred customer <br/>Reliable support for partners at all times<br/>We help you complete it step by step!",
			subtitle:"join now",
			contact:"We also offer other customized partner programs, <br/>contact us for more details",
			list:[
				{id:1,title:"Partner with a reliable forex broker:",desc:"",src:require("@/assets/images/partners/partners06.png")},
				{id:2,title:"Get started without investing money",desc:"",src:require("@/assets/images/partners/partners07.png")},
				{id:3,title:"Easy to use, no experience required",desc:"",src:require("@/assets/images/partners/partners08.png")},
				{id:4,title:"Marketing Support Technical Support/Free marketing tools",desc:"",src:require("@/assets/images/partners/partners09.png")},
				{id:5,title:"Detailed MT5 report",desc:"",src:require("@/assets/images/partners/partners010.png")},
				{id:6,title:"Competitive Commission",desc:"",src:require("@/assets/images/partners/partners011.png")}
			]
		}
	},
	mt5:{
		banner:{
			title:"MetaTrader 5",
			desc:"MetaTrader 5 builds on the solid foundation laid by the previous generation of platforms in the field of financial technology.Explore the latest features of MT5 and more tool options through YCHpro!",
			button:"Open A Live Account"
		},
		introduce:{
			title:"MetaTrader 5（MT5）",
			desc:"MetaTrader 5 - the fifth-generation MetaTrader platform is a powerful CFDs trading platform that supports currency pairs and other financial tradings.<br/> <br/>After MetaTrader 5 was launched in 2010 by a financial trading software development company, it quickly became the most popular trading platform for online forex traders and brokers around the world.",
			button:"Download now"
		},
		main:{
			title:"Main features of MetaTrader 5",
			list:[
				{id:1,title:"200+ tradings",desc:""},
				{id:2,title:"38+ pre-installed technical indicators",desc:""},
				{id:3,title:"8 pending order types",desc:""},
				{id:4,title:"21 timeframes",desc:""},
				{id:5,title:"3 chart types",desc:""},
				{id:6,title:"44 analytical charting tools",desc:""},
				{id:7,title:"Instant execution",desc:""},
				{id:8,title:"market execution",desc:""},
				{id:9,title:"Hedging system",desc:""},
				{id:10,title:"Built-in Forex Calendar",desc:""},
				{id:11,title:"Mobile stop loss",desc:""},
				{id:12,title:"technical analysis",desc:""},
				{id:13,title:"custom indicators",desc:""},
				{id:14,title:"intelligent trading (EA)",desc:""},
				{id:15,title:"Easy-to-use interface",desc:""},
				{id:16,title:"Fully Customizable Charts",desc:""}
			]
		},
		platform:{
			title:"MetaTrader 5 - Desktop Version",
			list:[
				{id:1,link:"https://download.mql5.com/cdn/web/14707/mt5/cwgmarketssvg5setup.exe",button:"Download Windows Version",title:"Download MT5 for Windows",desc:"MT 5 is an excellent trading platform that meets industry standards, download the latest version to your desktop computer and start trading immediately.With the smooth platform’s interface you can adjust it according to your personal preferences, and conduct trading and analysis based on the way you want.",src:require("@/assets/images/mt5/tp03.png")},
				{id:2,link:"https://download.metatrader.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg",button:"Download MAC version",title:"Download MT5 for Mac",desc:"Want to run on a Mac? Powerful solutions for your devices! Simply download the MT5 terminal for Mac OS X, install it immediately and start trading!",src:require("@/assets/images/mt5/tp04.png")},
				{id:3,link:"http://81.69.29.89:8099/",button:"Click skip",title:"Jump to web version MT5",desc:"MT5 Webtrader is a secure and advanced platform that allows you to easily manage your positions. All YCHpro clients can access this version of the platform through the desktop terminal, or click on the Webtrader link on the home page to continue.",src:require("@/assets/images/mt5/tp05.png")}
			]
		},
		mobile:{
			title:"MetaTrader 5 - Mobile Version",
			desc:"Download the MT5 trading platform to your mobile device, no matter where you are, you can always grasp the market conditions!<br/><br/>You can easily obtain the latest market trends and technical analysis information with just one tap on the mobile phone screen.",
			list:[
				{id:1,title:"App Store",link:require("@/assets/AppStore.jpg"),desc:"",src:require("@/assets/images/mt5/tp06.png")},
				{id:2,title:"Google Play",link:"",desc:"",src:require("@/assets/images/mt5/tp07.png")},
				{id:3,title:"Andriod",link:require("@/assets/Andriod.jpg"),desc:"",src:require("@/assets/images/mt5/tp08.png")}
			]
		}
	},
	open:{
		banner:"Just choose the account that works best for you. <br/>Trade wherever you want.",
		button:"Open A Live Account",
		account:[
			{id:1,title:"Micro trading account",desc:"Trade the world's largest market for as little as $100.Master the basics of trading with minimal risk.",button:"Open A Live Account",list:[
				{id:1,title:"Tradings",desc:"Forex, Precious Metals, Indices"},
				{id:2,title:"Minimum deposit",desc:"$100"},
				{id:3,title:"Spread Type",desc:"Float"},
				{id:4,title:"Spread",desc:"as low as 2.2"},
				{id:5,title:"Maximum leverage",desc:"1000"},
				{id:6,title:"EA",desc:"Yes"},
				{id:7,title:"Minimum trading volume",desc:"0.01 lot (forex), 0.01 lot (CFDs), 1 lot (index)"},
				{id:8,title:"Maximum trading volume",desc:"50 lots (foreign exchange), 25 lots (gold dollar, silver dollar), 150 (index)"}
			]},
			{id:2,title:"Standard account",desc:"For those who are not trading on their first day, we recommend opening a standard account.",button:"Open A Live Account",list:[
				{id:1,title:"Tradings",desc:"Forex, Precious Metals, Indices，CFDS"},
				{id:2,title:"Minimum deposit",desc:"$500"},
				{id:3,title:"Spread Type",desc:"Float"},
				{id:4,title:"Spread",desc:"as low as 1.0"},
				{id:5,title:"Maximum leverage",desc:"400"},
				{id:6,title:"EA",desc:"Yes"},
				{id:7,title:"Minimum trading volume",desc:"0.01 lot (forex), 0.01 lot (CFDs), 1 lot (index)"},
				{id:8,title:"Maximum trading volume",desc:"50 lots (foreign exchange), 25 lots (gold dollar, silver dollar), 150 (index)，25shou (CFDs)"}
			]},
			{id:3,title:"VIP account",desc:"The advanced account has ultra-low spreads, which is the trading guarantee for professional traders.",button:"Open A Live Account",list:[
				{id:1,title:"Tradings",desc:"Forex, Precious Metals, Indices，CFDS,Commodities"},
				{id:2,title:"Minimum deposit",desc:"$3000"},
				{id:3,title:"Spread Type",desc:"Float"},
				{id:4,title:"Spread",desc:"as low as 0"},
				{id:5,title:"Maximum leverage",desc:"400"},
				{id:6,title:"EA",desc:"Yes"},
				{id:7,title:"Minimum trading volume",desc:"0.01 lot (forex), 0.01 lot (CFDs), 1 lot (index)"},
				{id:8,title:"Maximum trading volume",desc:"50 lots (foreign exchange), 25 lots (gold dollar, silver dollar), 150 (index)，25shou (CFDs)"}
			]}
		]
	},
	access:{
		banner:"YCHpro allows your account to be funded quickly and securely，<br/>Trusted and Regulated,Safe and protected",
		cash:{
			title:"Deposits and Withdrawal",
			desc:"We provide a wide range of deposit and cash back methods through secure fund accounts to maximize customer convenience, deposit and cash back funds using the trading platform anytime, anywhere",
			disclaimer:"Disclaimer: YCHpro accepts no responsibility or claim for any failure or delay in depositing or withdraw funds in a trading account due to reasons beyond our control.",
			list:[
				{id:1,src:require("@/assets/images/custom/access01.png"),title:"Mastercard",subtitle:"Accepted Currencies:",button:"Deposit",desc:"AUD, USD, GBP, EUR, AED, SGD, CAD, CHF, HKD, NZD",top:"INSTANT PROCESSING",bot:"INTERNAL FEES",num:"ZERO"},
				{id:2,src:require("@/assets/images/custom/access02.png"),title:"Visa",subtitle:"Accepted Currencies:",button:"Deposit",desc:"AUD, USD, GBP, EUR, AED, SGD, CAD, CHF, HKD, NZD",top:"INSTANT PROCESSING",bot:"INTERNAL FEES",num:"ZERO"},
				{id:3,src:require("@/assets/images/custom/access03.png"),title:"skrill",subtitle:"Accepted Currencies:",button:"Deposit",desc:"AUD,USD, GBP EUR,NZD,SGD",top:"INSTANT PROCESSING",bot:"INTERNAL FEES",num:"ZERO"},
				{id:4,src:require("@/assets/images/custom/access04.png"),title:"Neteller",subtitle:"Accepted Currencies:",button:"Deposit",desc:"AUD, USD, GBP EUR, SGD",top:"INSTANT PROCESSING",bot:"INTERNAL FEES",num:"ZERO"},
				{id:5,src:require("@/assets/images/custom/access05.png"),title:"Bank/Wire Transfer",subtitle:"Accepted Currencies:",button:"Deposit",desc:"AUD, USD, GBP EURNZD",top:"1 - 3 business days processing",bot:"INTERNAL FEES",num:"ZERO"},
				{id:6,src:require("@/assets/images/custom/access06.png"),title:"Fasapay",subtitle:"Accepted Currencies:",button:"Deposit",desc:"USD",top:"INSTANT PROCESSING",bot:"INTERNAL FEES",num:"ZERO"}
			],
			button:'Deposit'
		},
		annotate:[
			{id:1,title:"Deposit Fee",desc:"YCHpro does not charge any fees for deposits or withdrawals. However, transfers between international banks may incur transfer fees. Customer shall be responsible for any such charges.<br/><br/>We do not accept any payments made through third party channels<br/><br/>Please use your own account to make payment.<br/><br/>If payment is made through a third party, we may incur an administrative processing fee.<br/><br/>We do not charge any transfer fees for deposits and withdrawals, but intermediary banks or e-wallets may charge fees."},
			{id:2,title:"Withdrawal priorities",desc:"Withdrawals will preferably be made via the same payment method as your initial deposit.<br/><br/>When the user deposits with a credit card, no matter which withdrawal method the user chooses, the withdrawal method will use the customer's credit card in priority until the withdrawal amount is consistent with the credit card deposit amount; after the credit card deposit limit is fully refunded, the remaining funds will be withdrawn using other payment methods Method; After withdrawing the deposit limit of the above two methods, YCHpro will use other methods to handle customer withdrawals, such as bank wire transfer or continue to use payment channels to withdraw from customers."},
		]
	},
	faq:{
		title:"FAQ",
		desc:"We have covered from how to open a foreign exchange trading account to how to use the MetaTrader trading platform, whether you are new to foreign exchange trading or a professional with several years of trading experience, you can find a lot of useful information in these frequently asked questions.",
		list:[
			{id:1,title:"Trading basis",desc:"",list:[
				{id:1,title:"What is a margin?",desc:"Margin is the amount required in your account to open a trade.",show:false},
				{id:2,title:"How is my forex margin requirement calculated?",desc:"To calculate the forex margin requirement, you need the following formula: (Market Quote * Volume) / Leverage = $Margin Requirement. For example, the current EURUSD quote is 1.13729, and we want to trade a standard lot (1,100) with an account leverage of 100:000. The calculation formula is (1.13729 * 100,000) / 100 = -1137.30 USD  Note: If the base currency of your account is not USD, you can simply convert this amount to the corresponding currency AUD, GBP, NZD, etc.",show:false},
				{id:3,title:"What is a margin call notice?",desc:"Since margin is essentially the collateral required to do trading, if the market trend is opposite to the client's position and your overall account net worth decreases, you may not have enough funds to maintain that position. If your net worth (balance open profit/loss) is less than 50% of the margin required to maintain your open position, the system will automatically close the maximum loss position in an attempt to increase your net worth.",show:false},
				{id:4,title:"What are the margin call and forced liquidation levels?",desc:"The margin call and forced liquidation levels are 80% and 50% respectively.",show:false},
				{id:5,title:"What is leverage?",desc:"Your account leverage is a multiplier to your ability to buy large amounts of currency and will determine how much margin you need to open a position. For example, if your leverage is 300:1, you can control a large position ($150,000, $500) with a small margin ($150).",show:false},
				{id:6,title:"Do you ban any trading styles, how long do I have to hold the trade at least?",desc:"No, we do not prohibit any trading style, you can open and close positions as you like.",show:false},
				{id:7,title:"What is the relationship between margin and leverage?",desc:"Margin is the amount of deposit required to open a trade, while leverage is capital borrowed from a broker to obtain a larger trading position. Therefore, margin foreign exchange trading enables traders to open larger positions with relatively small deposits. It is important to remember that leveraged trading can be risky because both losses and profits are magnified.",show:false},
				{id:8,title:"What is the account balance?",desc:"When trading, balance refers to the amount a trader has in their trading account. However, it is important to remember that this amount does not include any profit or loss a trader may have made on any open positions. If a trader has open positions, his or her balance may change based on his or her losses or profits after the trade (or part of it) is closed.<br/><br/>A trader's balance is located in different parts of the trading platform, depending on whether the trader is using the MT4 or MT5 platform. On the MT4 client terminal, the balance is displayed in the 'Terminal' window under the 'Trade' tab. On MT5, the balance can be seen in the 'Toolbox' under the 'Trade' tab.",show:false},
			]},
			{id:2,title:"Trading platform",desc:"",list:[
				{id:1,title:"How to enable the message function on the trading platform?",desc:'Log into the platform, go to the "Tools" tab, click "Options" then "Server", check the "Enable News" box, click "OK".<br/><br/>Please note that during major economic news announcements, the "News" option may require additional CPU usage, which may slow down the platform.',show:false},
				{id:2,title:"How to set stop loss and take profit?",
				desc:"Customers can set stop loss and take profit on the trading software by themselves.<br/><br/>The validity period of the stop loss and take profit can be viewed in the trading specification (GTC Mode) on the MT4 or MT5 software.<br/><br/>If you set a stop loss and take profit on the same day, you need to reset the stop loss and take profit the next day.<br/><br/>Stop loss level: also known as stop loss level, is the minimum distance from the current price that needs to be set for stop loss or take profit. For example, if you buy long orders in Europe and the United States at 1.11050, and the stop loss level is 40, it means that the stop loss and take profit need to be set at 4 basis points above and below 1.1150, that is, the maximum stop loss is 1.11010, and the minimum take profit is 1.11090. Different platform stop loss level regulations is different.<br/><br/>GTC mode: Refers to the effective time of pending orders, not orders that have already been traded",show:false},
				{id:3,title:"How long does it take for a pending order to expire?",desc:"1.Forex and precious metal pending order setting: always effective (until the customer cancels)<br/><br/>2.Futures and spot oil pending order setting: effective on the same day (excluding stop loss and take profit)<br/><br/>3.Pending order setting for indices and index futures: valid on the day (including stop loss and take profit)",show:false},
				{id:4,title:"Trading account overnight interest settlement time?",desc:"The trading account overnight interest settlement time is:<br/><br/>Every trading day - GMT time Summer time (+3): 23:59; Winter time (+2) 23:59<br/><br/>Summer time: The platform time shows GMT+3<br/><br/>Winter time: The platform time shows GMT+2 ",show:false},
				{id:5,title:"What is lockup?",desc:"Lock-up means that after futures investors buy and sell futures contracts, when the market shows a trend opposite to their own operations, they open a new position opposite to the original position. If you use the lock order, the two-way two positions will exist at the same time, and will not cancel each other. Generally speaking, the lock order is used to lock the current loss or profit, and then deal with it until the right time comes. It is also called pair lock and lock order.",show:false},
				{id:6,title:"What is liquidation?",
				desc:'The process of zeroing the account margin due to forced liquidation due to insufficient margin is called "liquidation".<br/><br/>When in addition to the used margin in our account, the remaining money, that is, the available margin is 0, the position will be liquidated. That is, forced liquidation. Usually the forced liquidation starts from the order with the largest loss until the margin ratio returns to the ratio stipulated by the platform.<br/><br/>The platform has set a liquidation line. When the margin is not enough to support the order, all the orders held at that time will be liquidated.',show:false},
			]},
			{id:3,title:"Trading account",desc:"",list:[
				{id:1,button:"Open A Live Account",title:"How to open a live trading account?",desc:"Click on 'Open A Live Account', complete the form and when complete you will receive an email with your login details which you can use to log into our secure members area.",show:false},
				{id:2,title:"How long does it take to open a live trading account with you?",desc:"Response to all new trading account inquiries within 24 hours.",show:false},
				{id:3,title:"What account base currency can I hold trading funds in?",desc:"Funds can be held in US dollars.",show:false},
			]}
		]
	},
	contact:{
		title:"Contact us",
		banner:"Have questions or seek professional help?<br/> Contact our professional, multilingual customer service team<br/> to provide you with 24/5 excellent customer support.",
		box:{
			title:"Your 5 star customer service",
			desc:"Have questions or seek professional help? Contact our professional, multilingual customer service team to provide you <br/>with 24/5 excellent customer support.",
			tab:[
				{id:1,title:"Live Chat Support",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:2,title:"Request a Call Back",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:3,title:"support@YCHpro.com",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:4,title:"Get help anytime from Customer Service",desc:"",src:require("@/assets/images/custom/contactUs01.png")}
			]
		},
		form:{
			button:"Submit",
			tips:"By submitting information, I confirm that I agree to the YCHpro Terms and Conditions and Privacy Policy and may contact you regarding my application.",
			list:[
				{id:2,type:'text',title:'Last Name',value:'',key:'firstName'},
				{id:3,type:'text',title:'Email Address',value:'',key:'email'},
				{id:4,type:'select',title:'Please Select a Country',value:'',key:'country'},
				{id:5,type:'text',title:'Phone number',value:'',key:'phone'},
				{id:6,type:'textarea',title:'Your Message',value:'',key:'content'}
			]
		}
	},
	activity:{
		banner:{
			title:"From now on, YCHpro customers can apply for a 10% bonus for each deposit, up to $2000, to help your trading.",
			button:"Open A Live Account",
			list:[
				{id:1,title:"Up to $2000 bonus",desc:""},
				{id:2,title:"Every deposit can apply it",desc:""},
				{id:3,title:"90 days super long trading period",desc:""},
				{id:4,title:"Enjoy EA trading",desc:""},
				{id:5,title:"Low-cost trading is guaranteed",desc:""}
			]
		},
		two:{
			title:"Up to $2000 bonus, you can apply for every deposit, start your trading journey immediately!",
			desc:"10% bonus and the cumulative maximum bonus amount is 5,000 US dollars.<br/>Example: If the customer deposits $10,000, if a 10% bonus is applied when depositing, the account will be credited with $1,000, and 200 standard lots must be completed.",
			list:[
				{id:1,title:"Application conditions",desc:"Every deposit can apply"},
				{id:2,title:"Bonus amount",desc:"10% of deposit amount"},
				{id:3,title:"Lot Requirements",desc:"20% of the bonus amount"},
			],
			problemList:[
				{id:1,title:"What is a welcome bonus? Who can apply for the Welcome Bonus?",desc:"The welcome bonus is a high-value bonus welcome gift specially prepared by YCHpro to thank new and old customers. During the event period, YCHpro customers can apply for a 10% welcome bonus for each deposit, and the cumulative maximum bonus will not exceed 2,000 US dollars."},
				{id:2,title:"How to apply for 10% Welcome Bonus?",desc:"The applying account only need to tick the 10% bonus in the customer zone when depositing, and the bonus will be credited to the deposit account within one working day."},
				{id:3,title:"If you deposit multiple times, how should the validity period be calculated?How long is the validity period of the bonus? ",desc:"The validity period of the welcome bonus is within 90 days after the first bonus is issued. The trading time and lot size are calculated from the first deposit of the bonus in the account, and all overdue bonuses will be automatically deducted."},
			]
		}
	},
	college:{
		title:"Develop your trading skills with YCHpro Academy",
		desc:"Learn investment knowledge and master trading skills through free online courses, video tutorials, articles written by market analysts, seminars, etc.",
		list:[
			{id:1,title:"Learning Videos ",desc:"Help you quickly understand trading knowledge through our series of teaching videos.",src:require("@/assets/images/community/community02.png")},
			{id:2,title:"Market Analysis",desc:"Major news events that affect the international mainstream market economy are updated every day to help you grasp every opportunity in the market.",src:require("@/assets/images/community/community03.png")},
			{id:3,title:"Fundamental Analysis",desc:"Understand and master the fundamental factors and important financial data affecting the financial market",src:require("@/assets/images/community/community04.png")},
			{id:4,title:"Technical Analysis",desc:"Understand technical analysis methods such as technical indicators, K-line, K-line chart, K-line shape and morphology",src:require("@/assets/images/community/community05.png")},
			{id:5,title:"Trading Signal ",desc:"YCHpro market data provides powerful insights to help you find potential trading opportunities.",src:require("@/assets/images/community/community06.png")},
			{id:6,title:"Live TV",desc:"Online trading live broadcast during the whole market period every day to help you understand the market trend in real time",src:require("@/assets/images/community/community07.png")}

		]
	},
	marketOne:{
		banner:{
			title:"Marketing Research",
			desc:"Get overall market reports organized by our analysts, which contain analysis <br/>and insights on various important financial events",
			src:require("@/assets/images/market/market01.png")
		},
		listOne:[
			{id:1,title:"Time saving",desc:"Market researching will be done by our analysts-- all you need to do is checking the report",src:require("@/assets/images/market/market02.png")},
			{id:2,title:"Obtain exclusive and special information",desc:"You'll get the latest market opportunities earlier than anyone else",src:require("@/assets/images/market/market03.png")},
			{id:3,title:"Real profit",desc:"Use this information to maximize profits",src:require("@/assets/images/market/market04.png")}
		],
		listTwo:[
			{id:1,time:"2023 05.31",title:"【Focus of the week】US stocks sink as debt ceiling impasse continues to weigh on investors, RBNZ rate decision today",src:require("@/assets/images/market/market05.png"),
			desc:"Major US indices were lower on Tuesday, extending to the downside through the session, as debt limit negotiations extend further to the edge after little progress was made in further talks between the White House and Republican negotiators. The Nasdaq slumped 160 points 1.26% as the risk off tone and tightening economic conditions hit growth stocks particularly.<br/><br/>"+
			"Positive economic releases (though hardly top tier) from the Flash PMI’s and a rise in new home sales were firmly in the background of the debt ceiling elephant in the room which looks set to be the main driver of risk sentiment until a deal is done, with both sides in US politics seemingly far apart on this, risk sentiment is looking to be shaky for the near term at least.<br/><br/>"+
			"<b>FX markets</b><br/><br/>USD saw gains on Tuesday with a risk off tone in US equities helping The Dollar Index hit a high of 103.65 to test the resistance at last week’s highs. Yields were lower on the session paring earlier strength which did limit gains, a lack of Fed speakers also failed to give any other catalyst.<br/><br/>"+
			"JPY managed to buck the trend and saw marginal gains vs the USD. USDJPY trading up to 138.91 early in the session before hitting resistance, the Yen finding support as a haven as the risk tone soured , also helped by US Treasury yields grinding lower throughout the US afternoon.<br/><br/>"+
			"NZD and AUD saw weakness due to the risk tone with AUDUSD testing 0.6600 from highs of 0.6661 as risk sentiment remained downbeat, weaker iron ore and copper prices also hindered the Aussie. A key risk Wednesday is the RBNZ rate decision, where the bank is expected to hike by another 25bp but futures are pricing in a decent chance of a 50bp hike, NZD should see some volatility either way the bank goes.<br/><br/>"+
			"In today’s economic announcements, the major risk event will be the RBNZ rate announcement at 12:00pm AEST. This is going to be an interesting decision with the markets fully pricing in a 25bp hike, and around 40% on top of a 50bp hike. (35bp priced in by futures markets) The recent NZ budget where large spending measures were announced and seen as inflationary certainly gives the RBNZ to plow ahead with it’s rate tightening cycle, the question is, will they surprise markets with another supersized 50 or be satisfied with a 25, either way there is sure to be volatility in NZD at this time as markets reprice, one chart to watch is AUDNZD where current pricing puts it squarely in the historical buy zone, a “dovish” 25 should see a good mean reversion trade opportunity."},
			{id:2,time:"2023 05.31",title:"Commodity Currencies Down as US inflationary Pressures rise; RBA meeting",src:require("@/assets/images/market/market06.png"),
			desc:"The commodity currencies remain under pressure as markets grow increasingly risk-averse. US stocks fell sharply Friday, with the S&P 500 down 3.6% and the beleaguered NASDAQ off 4.2%. The NASDAQ is down 21% so far this year, its worst-ever start to a year.<br/><br/>"+
			"US stocks were hit Friday as the  Employment Cost Index (ECI), the most comprehensive measure of US labor costs, beat market expectations with a record leap in Q1 (data back to 1996). The report corroborated other measures of wage increases, such as the Atlanta Fed wage growth tracker, and raised fears of a wage/price spiral.<br/><br/>"+
			"Higher wages are also the definitive sign of a tight labor market. Remember that Fed Chair Powell has said the US labor market is “extremely, historically tight” and “volatilely hot.” Data like this only confirms that idea – and makes it more likely that the Fed will assume it can hike rates further without endangering its commitment to “maximum employment.”<br/><br/>"+
			"The result was that the market is now discounting 50 bps hikes for the rest of the year. That’s what would be necessary to get the fed funds rate to 3.0%-3.25% by the December meeting. The market is even pricing in a small chance of a 75-bps hike at this meeting (9%) or the July meeting (14%).<br/><br/>"+
			"The repriced fed funds rate sent bond yields higher as well. Note though that higher AU, CA, and NZ bond yields didn’t do anything to help those currencies. Or at least, they fell despite the higher bond yields.<br/><br/>"+
			"The repriced fed funds rate sent bond yields higher as well. Note though that higher AU, CA, and NZ bond yields didn’t do anything to help those currencies. Or at least, they fell despite the higher bond yields.<br/><br/>"+
			"Friday’s Eurozone headline inflation came out in line with expectations but the core rate was higher than expected (see table above). The European Central Bank targets headline inflation but the higher core reading is worrisome nonetheless because it suggests inflationary pressures aren’t just due to higher energy prices. ECB Chief Economist Lane Friday said the question is no longer about hiking, but rather “the scale and the timing of interest-rate normalization.” The fall in the euro would be an “important factor” in determining their forecasts, he added. Lane is usually one of the more dovish members of the Governing Council and so if he’s shifted to this view then it’s gone mainstream at least.<br/><br/>"+
			"It’s significant that Lane didn’t push back against the market’s current pricing. The market is currently pricing in around 1 percentage point – four 25 bps hikes – in the ECB’s deposit rate this year.<br/><br/>"},
			{id:3,time:"2023 05.31",title:"The dollar hit a two-month high on Wednesday, limiting safe-haven buying in gold, driven by the looming risk of a default on U.S. debt",src:require("@/assets/images/market/market07.png"),
			desc:"Following the release of the minutes of the May policy meeting, market bets that the Fed will raise interest rates at its next meeting in June rose slightly. Fed officials 'generally agreed' in May that the need for further rate hikes 'has become less certain,' but other officials warned of the need for the Fed to maintain options given the risks to persistent inflation.<br/><br/>"+
			"According to CME Group's FedWatch tool, Fed funds rate futures show a 6.14% probability of a Fed rate hike at the end of its two-day policy meeting on June 35.<br/><br/>"+
			"The dollar index, which tracks the greenback against six major currencies, earlier reached 103.91, the highest since March 3. The index rose 20.0% to 319.103 in late trading.<br/><br/>"+
			"Marc Chandler, chief market strategist at Bannockburn Global Forex in New York, said he suspects debt ceiling negotiations have become an important factor in the FX market.<br/><br/>"+
			"'The dollar has been more or less up for three weeks with the help of stronger-than-expected data and rising U.S. interest rates,' Chandler said, adding that economic data could continue to support the dollar, with the Atlanta Federal Reserve expecting the U.S. economy to grow at an annualized rate of 2.9 percent in the second quarter. The pound fell to a five-week low of $1.2358 against the dollar, down 0.42% in late trading after data showed that inflation in the UK slowed much less than market expectations.<br/><br/>"+
			"The pound also fell against the euro, falling 0.26% to 1.1495 euros in late trading. Inflation in the eurozone's core services sector, reported on Tuesday, remained high, hitting the Swedish krona, and the European Central Bank is set to raise interest rates in June and July. The Swedish krona hit 6.7 kronor against the euro, the lowest level since March 11.<br/><br/>"},
			{id:4,time:"2023 05.31",title:"The US CPI is imminent, and the US dollar currency treats the breakout",src:require("@/assets/images/market/market08.png"),
			desc:"After entering a volatile trend for nearly a month, the dollar did not give any clear vote after the announcement of the Fed's interest rate and monetary policy last week, depending on the fact that there has not been any manifestation of a clear trend, so the market has not been much volatile. According to the content of the Fed's Powell press conference afterwards, the market is likely to use this week's US CPI data as a forecast for the direction of the Fed's monetary policy.<br/><br/>"+
			"Therefore, the dollar is likely to cause some volatility after the CPI announcement this week. If the CPI data does not fall significantly or even rise, this is likely to give the dollar a certain rebound; Conversely, the decline in CPI data may put some pressure on the dollar, which is expected to break through the recent support point.<br/><br/>"}
		]
	},
	marketTwo:{
		banner:{
			title:"Trading Signal Analysis",
			desc:"The professional trading team provides daily trading signal analysis to <br/>find ideal trading opportunities.",
			src:require("@/assets/images/market/marketTwo01.png")
		},
		list:[
			{id:1,title:"Daily chart of the US dollar index:",src:require("@/assets/images/market/marketTwo02.png"),
			desc:"100.900 – 101.500 can be regarded as the current main support area, the upper pressure key level falls at 102.450, effectively breaking through these two key levels or attracting a more obvious dollar trend, traders can pay more attention to whether the price trend after the CPI index can break through these key levels.<br/><br/>"},
			{id:2,title:"EURUSD EURUSD：",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"EUR/USD has been in the range of almost 1.1000 integers over the past month, and whether the pair can break through this level is the key to the pair's next movement. Judging from the current trend, EURUSD is in the support of the volatility range and small rising channel, and in terms of intraday trading, it can currently be regarded as the price of intraday trading long. Traders with higher risk appetite can hold positions after the CPI data is released with appropriate stop loss.<br/><br/>"+
			"In addition, traders can wait for the release of CPI data and the trend of the US dollar to form a more obvious trend before looking for entry opportunities.<br/><br/>"+
			"If the CPI index is bearish for the US dollar, traders can wait for the price to be stable above 1.1000 before entering the market;<br/><br/>"+
			"Conversely, if EURUSD may form a trend reversal and fall in a bullish USD situation, traders can short EURUSD after effectively breaking through the key support at 1.09500, and the next target key level can be seen in the area of 1.0800 – 1.07800.<br/><br/>"},
			{id:3,title:"Dollar Index Technical Analysis:",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"The U.S. index's rise on Wednesday was blocked below 103.95, and its decline above 103.30 was supported, meaning that the dollar is likely to maintain its upward trend after a short-term decline. If today's decline above 103.70 is supported, the target for the future rally will be between 104.10-104.30. Today, the short-term resistance of the US index is 104.05--104.10, and the short-term important resistance is 104.25--104.30. Today, the US index short-term support is at 103.70--103.75, and short-term important support is at 103.50--103.55.<br/><br/>"},
			{id:4,title:"EUR/USD Technical Analysis:",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"The decline in Europe and the United States on Wednesday was supported above 1.0745, and the rise was blocked below 1.0805, indicating that the short-term rise in Europe and the United States is likely to maintain the downward trend. If the rise in Europe and the United States is blocked below 1.0770 today, the target for the decline will point to the range of 1.0730-1.0710. Today, the short-term resistance in Europe and the United States is 1.0770--1.0775, and the short-term important resistance is 1.0780--1.0785. Today, the short-term support in Europe and the United States is at 1.0730--1.0735, and the short-term important support is at 1.0710--1.0715.<br/><br/>"},
			{id:5,title:"Gold Technical Analysis:",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"Gold's decline on Wednesday was supported above 1956.00, and its rise was blocked below 1985.00, implying that gold is likely to maintain its downward trend after a short-term rise. If gold's rise today is blocked below 1968.00, the target for the decline will be between 1949.00-1938.00. Today's short-term resistance for gold is 1967.00-1968.00, and short-term important resistance is 1977.00--1978.00. Today gold short-term support is 1949.00-1950.00, short-term important support is 1938.00-1939.00."}
		]
	},
	marketThree:{
		banner:{
			title:"Economic Calendar",
			desc:"If you really want to be a trader, a trading plan will be an important part of your strategy. Find the economic calendar in our tool and plan your trades down to the minute based on upcoming economic reports, previous economic events, consensus forecasts and estimated fluctuations",
			button:"Trade Now"
		}
	}

}
