<template>
	<div id="tradingSignalAnalysis">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="tradbanner" v-if="mainJson.block1" :style="{backgroundImage:'url('+mainJson.block1.img+')'}">
			<div class="bannerBox">
				<div class="bannerTitle" v-html="mainJson.block1.title"></div>
				<div class="bannerDesc" v-html="mainJson.block1.desc"></div>
			</div>
		</div>
		<!--  -->
		<!-- <div class="tradList">
			<router-link :to="'/tradingSignalAnalysisDetails?index='+index" class="sameTradList" 
			v-for="(item,index) in mainJson.block2" :key="index">
				<div class="title" v-html="item.title"></div>
				<img :src="item.img"/>
				<div class="desc" v-html="item.desc"></div>
			</router-link>
		</div> -->
		<div class="tradList">
			<router-link :to="'/tradingSignalAnalysisDetails?id='+item.id" class="sameTradList" 
			v-for="(item,index) in newsList" :key="index">
				<div class="sameTradListOne" v-if="index == 0">
					<img :src="item.cover"/>
					<div class="right">
						<div class="title" v-html="item.title"></div>
						<div class="newtime">{{item.author?item.author+'-':''}}{{item.newTime}}</div>
						<div class="desc" v-html="item.remark"></div>
						<div class="imgBox">
							<img v-for="(sitem,sindex) in item.iconList" :key="sindex" :src="sitem"/>
						</div>
					</div>
				</div>
				<div class="sameTradListTwo" v-if="index != 0">
					<div class="samemarketListRight">
						<div class="newsTitle" v-html="item.title"></div>
						<div class="newtime">{{item.author?item.author+'-':''}}{{item.newTime}}</div>
						<div class="newsDesc" v-html="item.remark"></div>
						<div class="imgBox">
							<img v-for="(sitem,sindex) in item.iconList" :key="sindex" :src="sitem"/>
						</div>
					</div>
					<img :src="item.cover"/>
				</div>
			</router-link>
			<div class="pageBox discen" v-if="totalPage>0">
				<div class="pageButton discen" :class="page==1?'pageButtonPrev':''" @click="prevPage">{{$t('element.prevPage')}}</div>
				<div class="pageNum discen" v-for="(item,index) in totalPage" :key="index" 
				:class="(index+1 == page)?'pageNumCun':''" @click="currenPage(index+1)">{{index+1}}</div>
				<div class="pageButton discen" @click="nextPage" :class="page==totalPage?'pageButtonPrev':''">{{$t('element.nextPage')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tradingSignalAnalysis',
		data() {
			return {
				mainJson:{},
				page:1,
				newsList:[],
				totalPage:1,
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			this.getNewsList();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			// 上一页
			prevPage(){
				if(this.page>1){
					this.page = this.page - 1;
					this.getNewsList();
				}
			},
			// 下一页
			nextPage(){
				if(this.page < this.totalPage){
					this.page = this.page + 1;
					this.getNewsList();
				}
			},
			// 当前页
			currenPage(page){
				this.page = page;
				this.getNewsList();
			},
			getNewsList(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=10'+'&page='+this.page+'&module=signal_analysis').then((res)=>{
					// console.log('res',res)
					this.totalPage = Math.ceil(res.total/10);
					 res.rows.forEach((item,index)=>{
					 	item['newTime'] = this.formatPast(item.createTime);
						if(item.icons){
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
					 })
					this.newsList = res.rows;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=trading_signal_analysis.json':'getJsonConfig?fileName=trading_signal_analysis_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block2.length>0&&configData.block2[0].hasOwnProperty('sortNo')){
						configData.block2 =  (configData.block2).sort((a, b) => a.sortNo - b.sortNo);
					}
					(configData.block2).forEach((item,index)=>{
						item['newTime'] = this.formatPast(item.time);
						if(item.icons){
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
					})
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			formatPast(date, type = "default", zeroFillFlag = true){
				// 定义countTime变量，用于存储计算后的数据
				let countTime;
				// 获取当前时间戳
				let time = new Date().getTime();
				// 转换传入参数为时间戳
				let afferentTime = new Date(date).getTime();
				// 当前时间戳 - 传入时间戳
				time = Number.parseInt(`${time - afferentTime}`);
				if (time < 10000) {
				    // 10秒内
				    return "刚刚";
				} else if (time < 60000) {
				    // 超过10秒少于1分钟内
				    countTime = Math.floor(time / 1000);
				    return `${countTime}秒前`;
				} else if (time < 3600000) {
				    // 超过1分钟少于1小时
				    countTime = Math.floor(time / 60000);
				    return `${countTime}分钟前`;
				} else if (time < 86400000) {
				    // 超过1小时少于24小时
				    countTime = Math.floor(time / 3600000);
				    return `${countTime}小时前`;
				} else if (time >= 86400000 && type == "default") {
				    // 超过二十四小时（一天）且格式参数为默认"default"
				    countTime = Math.floor(time / 86400000);
				    //大于等于365天
				    if (countTime >= 365) {
				        return `${Math.floor(countTime / 365)}年前`;
				    }
				    //大于等于30天
				    if (countTime >= 30) {
				        return `${Math.floor(countTime / 30)}个月前`;
				    }
				    return `${countTime}天前`;
				} else {
				    // 一天（24小时）以上且格式不为"default"则按传入格式参数显示不同格式
				    // 数字补零
				    let Y = new Date(date).getFullYear();
				    let M = new Date(date).getMonth() + 1;
				    let zeroFillM = M > 9 ? M : "0" + M;
				    let D = new Date(date).getDate();
				    let zeroFillD = D > 9 ? D : "0" + D;
				    // 传入格式为"-" "/" "."
				    if (type == "-" || type == "/" || type == ".") {
				        return zeroFillFlag
				            ? Y + type + zeroFillM + type + zeroFillD
				            : Y + type + M + type + D;
				    }
				    // 传入格式为"年月日"
				    if (type == "年月日") {
				        return zeroFillFlag
				            ? Y + type[0] + zeroFillM + type[1] + zeroFillD + type[2]
				            : Y + type[0] + M + type[1] + D + type[2];
				    }
				    // 传入格式为"月日"
				    if (type == "月日") {
				        return zeroFillFlag
				            ? zeroFillM + type[0] + zeroFillD + type[1]
				            : M + type[0] + D + type[1]
				    }
				    // 传入格式为"年"
				    if (type == "年") {
				        return Y + type
				    }
							
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tradList{
		width: 12rem;
		height: auto;
		margin: 1.1rem auto 2.6rem;
		.sameTradList{
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 1.1rem;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			// >img{
			// 	display: block;
			// 	width: 100%;
			// 	height:auto;
			// 	margin-top: 0.3rem;
			// }
			// .title{
			// 	font-size: 0.32rem;
			// 	color: #202020;
			// 	font-weight: 700;
			// }
			// .desc{
			// 	font-size: 0.16rem;
			// 	line-height: 0.25rem;
			// 	color: #202020;
			// 	margin-top: 0.2rem;
			// }
			.sameTradListOne{
				width: 100%;
				height: auto;
				display: flex;
				justify-content: space-between;
				// align-items: center;
				>img{
					width: 55%;
					height: 4rem;
					margin-right: 0.25rem;
				}
				.right{
					flex: 1;
					height: auto;
					.title{
						font-size: 0.32rem;
						color: #202020;
						font-weight: 700;
					}
					.newtime{
						width: 100%;
						height: 0.4rem;
						line-height: 0.4rem;
						font-size: 0.18rem;
						color: #7F8284;
					}
					.desc{
						font-size: 0.16rem;
						line-height: 0.25rem;
						color: #202020;
						// margin-top: 0.2rem;
						margin-bottom: 0.2rem;
					}
					.imgBox{
						width: 100%;
						height: auto;
						display: flex;
						align-items: center;
						margin-top:0.1rem;
						>img{
							display: block;
							width: auto;
							height: 0.3rem;
							margin-right: 0.1rem;
						}
					}
				}
			}
			.sameTradListTwo{
				width: 100%;
				height: auto;
				// margin-bottom: 0.64rem;
				margin-bottom: 0.5rem;
				display: flex;
				>img{
					width: 2.66rem;
					height: 1.66rem;
					margin-left: 0.24rem;
				}
				.samemarketListRight{
					flex: 1;
					// height: 1.5rem;
					height: auto;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-size: 0.16rem;
					color: #7F8284;
					.newtime{
						width: 100%;
						height: 0.4rem;
						line-height: 0.4rem;
						font-size: 0.18rem;
						color: #7F8284;
					}
					.newsTitle{
						width: 100%;
						height:auto;
						font-size: 0.2rem;
						color: #29261C;
						font-weight: 700;
						// line-height: 0.3rem;
						// margin-bottom: 0.16rem;
						white-space: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}
					.newsDesc{
						width: 100%;
						height: 0.5rem;
						font-size: 0.16rem;
						color: #7F8284;
						line-height: 0.25rem;
						white-space: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						// margin-bottom: 0.2rem;
					}
					.imgBox{
						width: 100%;
						height: auto;
						display: flex;
						align-items: center;
						margin-top:0.1rem;
						>img{
							width: 0.3rem;
							height: 0.3rem;
							margin-right: 0.1rem;
						}
					}
				}
			}
		}
	}
	.tradbanner{
		width: 100%;
		height: 4.3rem;
		background-size: cover;
		.bannerBox{
			width: 100%;
			height: 4.3rem;
			padding-top: 0.9rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.bannerTitle{
				font-size: 0.44rem;
				color: #FFFFFF;
				text-align: center;
			}
			.bannerDesc{
				font-size: 0.16rem;
				color: #FFFFFF;
				margin:0.3rem 0 0;
				text-align: center;
				line-height: 0.3rem;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.tradList{
			width: 90%;
		}
		.tradbanner{
			width: 100%;
			padding: 0 0.3rem;
		}
		
	}
</style>